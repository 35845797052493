import React from "react";

const AmbassadorDivider = ({ addition = "" }) => {
	return (
		<div style={{ height: "1px", backgroundColor: "#D9DBE9" }} className={`${addition} w-full`}>
			{" "}
		</div>
	);
};

export default AmbassadorDivider;
