const initialState = {
    transactionBreakdown: {},
    transactions: []
}


export default function transactions (state= initialState, action){

    switch (action.type) {
        case 'LOAD_TRANSACTION_BREAKDOWN':
            return {
                ...state,
                transactionBreakdown: action.payload
            }
        break;

        case 'LOAD_TRANSACTIONS':
            return {
                ...state,
                transactions: action.payload
            }
        break;
    

        default:
        return state;
    }

    return state;
}