import React, { useState, useEffect } from "react";
import AgentCard from "../../../NewAmbassadorComponents/AgentCard/AgentCard";
import { history } from "../../../App";
// import { useHistory } from "react-router-dom";

import DurationDropdown from "../../../NewAmbassadorComponents/DurationDropdown/DurationDropdown";
import SearchInput from "../../../NewAmbassadorComponents/Form/SearchInput/SearchInput";
import AgentSearchResult from "../Agents/AgentSearch/AgentSearch";
import ScrollToTop from "../../../NewAmbassadorComponents/ScrollRestoration/ScrollRestoration";
import NewPagination from "../../../NewAmbassadorComponents/NewPagination/NewPagination";
import SelectInput from "../../../NewAmbassadorComponents/SelectInput.js/SelectInput";
import useFetchWithParams from "../../../Hooks/useFetchWithParams";
import { agentSearch, agentSearch2 } from "../../../Services/Agents";
import NewAmbassadorCard from "../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import AmbassadorDivider from "../../../NewAmbassadorComponents/Divider/AmbassadorDivider";
import FullScreenLoader from "../../../NewAmbassadorComponents/Loader/FullScreenLoader";
import SearchInputWithSelect from "../../../NewAmbassadorComponents/Form/SearchInput/SearchInputWithSelect";

const searchOptions = [
	// {
	// 	label: "Agent Group",
	// 	value: "agentGroupId",
	// },
	// {
	// 	label: "POS Type",
	// 	value: "posType",
	// },
	{
		label: "Business Name",
		value: "businessName",
	},
	// {
	// 	label: "Username",
	// 	value: "username",
	// },
	{
		label: "Phone Number",
		value: "phone",
	},
	// {
	// 	label: "Member Id",
	// 	value: "memberId",
	// },
];

const dropDownOptions = [
	{ label: "ALL", value: 0 },
	{ label: "BASIC", value: 4 },
	{ label: "CHAMPION", value: 7 },
	{ label: "INACTIVE", value: 1 },
	{ label: "PARTNER", value: 5 },
	{ label: "PREMIUM", value: 6 },
	{ label: "ROOKIE", value: 2 },
	{ label: "SUBSCRIBER", value: 3 },
];

const AllAgents = () => {
	const [searchResultArray, setSearchResultArray] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchFilter, setSearchFilter] = useState(null);
	const [showSearchResult, setShowSearchResult] = useState(false);

	const [dropDownValue, setDropDownValue] = useState(0);
	const [label, setLabel] = useState("ALL");

	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState({
		label: "Business Name",
		value: "businessName",
	});
	const [totalPages, setTotalPages] = useState(1);
	// console.log(totalPages);
	// const [pageSize, setPageSize] = useState(25);

	const changePage = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
		// console.log(selected);
	};

	const _handleDropdownValue = (value) => {
		setCurrentPage(1);
		setDropDownValue((prev) => value);
	};

	const { isFetching: isLoading2 } = useFetchWithParams(
		["agent-search3", { value: searchFilter }],
		agentSearch,
		{
			enabled: searchFilter !== null ? true : false,
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			onSuccess: (data) => {
				setSearchResultArray(data.data.data);
				setShowSearchResult(true);
			},
		}
	);

	const { isLoading, data, isSuccess } = useFetchWithParams(
		["agent-list2", { page: currentPage, agentFilter: dropDownValue }],
		agentSearch2,
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			refetchOnMount: true,
			onSuccess: (data) => {
				setTotalPages(data.data.recordsTotal);
			},
		}
	);

	const hasData = isSuccess && data !== undefined && data.data.data.length > 0;
	const isEmpty =
		isSuccess && data !== undefined && data.data.data.length === 0;

	// const history = useHistory();

	const handleBackClick = () => {
		setSearchFilter(null);
		setShowSearchResult(false);
		setSearchValue("");
		setSearchResultArray((prev) => []);
		enableOverFlow();
	};

	const _handleGoClick = () => {
		if (searchBy.value && searchValue.trim()) {
			const filter = `${searchBy.value}=${searchValue}`;
			setSearchFilter(filter);
		}
	};

	const _handleClear = () => {
		setSearchValue("");
		setSearchFilter(null);
	};

	const _handleSearchByChange = (val) => {
		setSearchBy((prev) => val);
	};

	const handleInputChange = (e) => {
		setSearchValue(e.target.value);
	};

	const showPagination = Math.ceil(totalPages / 30) > 1;

	const preventOverflow = () => {
		const bodyElement = document.querySelector("body");
		bodyElement.style.overflowY = "hidden";
	};

	const enableOverFlow = () => {
		const bodyElement = document.querySelector("body");
		bodyElement.style.overflowY = "scroll";
	};
	useEffect(() => {
		if (showSearchResult) {
			preventOverflow();
		} else {
			enableOverFlow();
		}
	}, [searchResultArray]);

	return (
		<div>
			<ScrollToTop />
			{showSearchResult && (
				<AgentSearchResult
					data={searchResultArray}
					backAction={handleBackClick}
				/>
			)}
			{isLoading2 && <FullScreenLoader />}

			<div className="tw-flex tw-justify-between tw-items-center">
				<div
					onClick={() => {
						history.goBack();
					}}
					className="tw-flex tw-cursor-pointer tw-items-center"
				>
					<img
						src={require("../../../assets/icons/back-arrow.svg")}
						className="tw-w-5 tw-mr-4"
						alt=""
					/>

					<span className="tw-text-black tw-font-bold">Agents</span>
				</div>

				<DurationDropdown
					value={dropDownValue}
					label={label}
					setFunction={_handleDropdownValue}
					setLabel={setLabel}
					options={dropDownOptions}
				/>
			</div>
			<div className="tw-mt-6">
				<SearchInputWithSelect
					options={searchOptions}
					selectValue={searchBy}
					selectChange={_handleSearchByChange}
					onChange={handleInputChange}
					onClear={_handleClear}
					value={searchValue}
					onSubmit={_handleGoClick}
					placeholder="Search Agent"
					selectPlaceholder="Search By.."
				/>
			</div>

			<div className="tw-mt-8">
				{isEmpty && (
					<div
						style={{
							borderRadius: "10px",
							boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.04)",
						}}
						className="tw-h-24 tw-flex tw-justify-center tw-items-center tw-bg-white"
					>
						<p className="tw-text-pc-secondaryshade2 tw-mb-0 tw-font-semibold text-lg">
							No Agent Found
						</p>
					</div>
				)}

				{isLoading &&
					Array(10)
						.fill(1)
						.map((each) => {
							return <Loader />;
						})}
				{hasData &&
					data.data.data.map((each, index) => {
						return (
							<AgentCard
								key={`agentList2_${index}`}
								data={each}
								color="tw-bg-green-500"
							/>
						);
					})}
				{/* <AgentCard color="tw-bg-green-500" />
				
				<AgentCard color="tw-bg-yellow-500" />
				<AgentCard color="tw-bg-purple-500" />
				<AgentCard color="tw-bg-blue-500" />
				<AgentCard color="tw-bg-green-500" marginBottom="tw-mb-0" /> */}

				{showPagination && (
					<div className="tw-mt-10">
						<NewPagination
							totalPages={totalPages > 1 ? Math.ceil(totalPages / 30) : 1}
							currentPage={currentPage}
							changePage={changePage}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default AllAgents;

function Loader() {
	return (
		<NewAmbassadorCard addition={"tw-mb-5"}>
			<div className="tw-flex tw-mt-2 tw-mb-4 tw-justify-end">
				<span
					className={`${"bg-gray-200"} tw-animate-pulse tw-bg-gray-200 tw-h-6 tw-w-6 tw-rounded-full`}
				></span>
			</div>
			<div>
				<span className="tw-text-pc-grey9 pulse-loader tw-w-24 tw-text-pc-df-desktop"></span>
				<div className="tw-flex tw-justify-between tw-items-center">
					<p className=" tw-text-pc-secondaryshade1 pulse-loader tw-w-24 tw-font-bold tw-mb-0 tw-text-pc-sm-mobile"></p>

					<span
						className="tw-flex tw-items-center tw-justify-center tw-px-2 tw-bg-white tw-py-1 tw-border tw-rounded-md hover:tw-no-underline tw-border-pc-secondaryshade2"
						to="#"
					>
						<span className="tw-text-pc-secondaryshade2 pulse-loader tw-w-24 tw-text-pc-sm-mobile  tw-mr-1"></span>
						<img
							src={require("../../../assets/icons/chevron-right.svg")}
							className="tw-w-4 tw-ml-1"
							alt=""
						/>
					</span>
				</div>
			</div>
			<AmbassadorDivider addition="tw-my-4" />
			<div className="tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block pulse-loader tw-w-24 tw-text-pc-sm-mobile tw-text-pc-grey9"></span>
					<p className="tw-font-bold tw-mt-2 pulse-loader tw-w-24 tw-text-pc-df-mobile tw-mt-pc-6 tw-text-black"></p>
				</div>
				<div className="tw-text-right">
					<span className="tw-block pulse-loader tw-w-24 tw-text-pc-sm-mobile tw-text-pc-grey9"></span>
					<p className="tw-font-bold tw-mt-2 pulse-loader tw-w-24 tw-text-pc-df-mobile tw-mt-pc-6 tw-text-black"></p>
				</div>
			</div>
		</NewAmbassadorCard>
	);
}
