import React from "react";
import ScrollToTop from "../../../NewAmbassadorComponents/ScrollRestoration/ScrollRestoration";

import DashCard1 from "./DashCard1/DashCard1";
import DashCard2 from "./DashCard2/DashCard2";
import DashCard3 from "./DashCard3/DashCard3";
import DashCard4 from "./DashCard4/DashCard4";
import DashCard5 from "./DashCard5/DashCard5";

const NewAmbassadorDashboard = () => {
	return (
		<div>
			<ScrollToTop />
			<DashCard1 />
			<DashCard2 />
			<DashCard3 />
			<DashCard4 />
			<DashCard5 />
		</div>
	);
};

export default NewAmbassadorDashboard;
