export const colourStyles = {
	control: (styles: any, state: any) => ({
		...styles,
		backgroundColor: "#ffffff",
		color: "#4f4f4f",
		fontSize: "14px",
		borderRadius: "4px",
		borderColor: "#e2e8f0",
		minWidth: "130px",
		borderWidth: "1px",
		outline: "none",
		height: 10,
		":hover": {
			...styles[":hover"],
			ouline: "none",
			// boxShadow: "none",
			// backgroundColor: "#edf2f7",
		},
		// boxShadow: state.isFocused ? 0 : 0,
		"&:hover": {
			// border: state.isFocused ? 0 : 0,
		},
	}),

	menu: (styles: any, state: any) => ({
		...styles,
		zIndex: 1000,
	}),
	singleValue: (styles: any, state: any) => ({
		...styles,
		color: "#4f4f4f",
	}),
	placeholder: (styles: any) => ({
		...styles,

		color: "#cbd5e0",
	}),
	option: (styles: any) => {
		return {
			...styles,
			backgroundColor: "#fff",
			cursor: "pointer",
			color: "#718096",
			fontSize: "14px",

			border: "none",
			outline: "none",

			":active": {
				...styles[":active"],
				backgroundColor: "#DEF1FF",
			},
			":hover": {
				...styles[":hover"],
				backgroundColor: "#DEF1FF",
				border: "none",
				boxShadow: "none",
				outline: "none",
			},
		};
	},
};
export const colourStyles2 = {
	control: (styles: any, state: any) => ({
		...styles,
		backgroundColor: "#ffffff",
		color: "#4f4f4f",
		fontSize: "14px",
		borderRadius: "4px",
		borderColor: "transparent",
		minWidth: "100px",
		borderWidth: "1px",
		outline: "none",
		height: 10,
		":hover": {
			...styles[":hover"],
			ouline: "none",
			boxShadow: "none",
			// backgroundColor: "#edf2f7",
		},
		boxShadow: state.isFocused ? 0 : 0,
		"&:hover": {
			border: state.isFocused ? 0 : 0,
		},
	}),

	menu: (styles: any, state: any) => ({
		...styles,
		zIndex: 1000,
	}),
	dropdownIndicator: (styles: any, state: any) => ({
		...styles,
		color: "#4285F4",
	}),
	singleValue: (styles: any, state: any) => ({
		...styles,
		color: "#4f4f4f",
	}),
	placeholder: (styles: any) => ({
		...styles,

		color: "#9ca3af",
	}),
	option: (styles: any) => {
		return {
			...styles,
			backgroundColor: "#fff",
			cursor: "pointer",
			color: "#718096",

			border: "none",
			outline: "none",

			":active": {
				...styles[":active"],
				backgroundColor: "#DEF1FF",
			},
			":hover": {
				...styles[":hover"],
				backgroundColor: "#DEF1FF",
				border: "none",
				boxShadow: "none",
				outline: "none",
			},
		};
	},
};
