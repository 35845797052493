export const formatNumber = (num) => {
	
	if (isNaN(num) || num === "") return "";
	const _num = +num;
	if (Number.isInteger(_num)) return _num.toLocaleString();
	// if (_num === 0) return "0.00";
	const _first = _num.toFixed(2).split(".");
	const final = `${parseInt(_first[0]).toLocaleString()}.${_first[1]}`;
	return final;
};
