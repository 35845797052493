import React, { Component } from "react";
import { connect } from "react-redux";

import "./style.css";
import { NavLink, withRouter } from "react-router-dom";
import { signOut } from "../../utils/handleIsAuth";
import dashboard from "../../assets/img/dashboard.svg";
import pos from "../../assets/img/pos.svg";
import monitor from "../../assets/img/monitor.svg";
import transaction from "../../assets/img/transaction.svg";
import activation from "../../assets/img/activation.svg";
import logout from "../../assets/img/logout.svg";
import changePassword from "../../assets/img/password.svg";
import Referral from "../../assets/img/referral-awoof.svg";
import { logOutUser } from "../../redux/actions/userActions";

class SideNav extends Component {
  componentDidMount() {
    let navs = document.querySelectorAll("a");
    let a = [...navs];
    let activeNav = [];
    a.forEach((nav) => {
      if (nav.pathname === window.location.pathname) {
        activeNav.push(nav);
        return;
      } else {
        return;
      }
    });

    this.props.getHeader(activeNav[0].innerText);
  }

  componentDidUpdate() {
    let navs = document.querySelectorAll("a");
    let a = [...navs];
    let activeNav = [];
    a.forEach((nav) => {
      if (nav.pathname === window.location.pathname) {
        activeNav.push(nav);
        return;
      } else {
        return;
      }
    });
    activeNav[0].parentElement.style.backgroundColor = "#4285F4";
  }
  render() {
    return (
      <div className={`sideNav  ${this.props.openCloseSideNav}`}>
        <div className="sideNav_main">
          <ul>
            <li>
              <NavLink
                to="/"
                className="tw-flex tw-items-center"
                activeClassName="active"
                onClick={this._handleReloadPage.bind(this)}
              >
                <img src={dashboard} alt="#" />
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/transactions"
                className="tw-flex tw-items-center"
                activeClassName="active"
                onClick={this._handleReloadPage.bind(this)}
              >
                <img src={transaction} alt="#" />
                Transactions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/poscreate"
                className="tw-flex tw-items-center"
                activeClassName="active"
                onClick={this._handleReloadPage.bind(this)}
              >
                <img src={pos} alt="#" />
                POS Account
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/?monitor=true"
                className="tw-flex tw-items-center"
                activeClassName="active"
                onClick={this._handleReloadPage.bind(this)}
              >
                <img src={monitor} alt="#" />
                Transaction Monitor
              </NavLink>
            </li>
            <li>
              <span
                className="tw-flex tw-items-center"
                onClick={this.props.getActivationCode}
              >
                <img src={activation} alt="#" />
                Activation code
              </span>
            </li>
            <li>
              <NavLink
                to="/bvn"
                className="tw-flex tw-items-center"
                activeClassName="active"
                onClick={this._handleReloadPage.bind(this)}
              >
                <img src={changePassword} alt="#" />
                Update BVN
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/changePassword"
                className="tw-flex tw-items-center"
                activeClassName="active"
                onClick={this._handleReloadPage.bind(this)}
              >
                <img src={changePassword} alt="#" />
                Change Password
              </NavLink>
            </li>
            {/* <li>
							<span
								className="tw-flex tw-items-center"
								onClick={this.props._openAwoofModal}
							>
								<img src={Referral} alt="#" />
								Referral Awoof
							</span>
						</li> */}

            <li>
              <span
                className="tw-flex tw-items-center"
                onClick={this._handleSignOut.bind(this)}
              >
                <img src={logout} alt="#" />
                Log Out
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  _handleSignOut() {
    signOut().then(() => {
      this.props.history.push("/login");
      this.props.logOutUserFromRedux();
    });
  }

  _handleReloadPage(e) {
    if (e.target.pathname === window.location.pathname) {
      e.forceUpdate();
    } else {
      return;
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOutUserFromRedux: () => dispatch(logOutUser()),
  };
}

export default connect(null, mapDispatchToProps)(withRouter(SideNav));
