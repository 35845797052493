import React, { useState, useEffect } from "react";

import { history } from "../../../../App";
import useFetchWithParams from "../../../../Hooks/useFetchWithParams";
import NewAmbassadorCard from "../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import DatePicker from "../../../../NewAmbassadorComponents/DatePicker/DatePicker";
import AmbassadorDivider from "../../../../NewAmbassadorComponents/Divider/AmbassadorDivider";
import DurationDropdown from "../../../../NewAmbassadorComponents/DurationDropdown/DurationDropdown";
import NewPagination from "../../../../NewAmbassadorComponents/NewPagination/NewPagination";
import ScrollToTop from "../../../../NewAmbassadorComponents/ScrollRestoration/ScrollRestoration";
import TransactionCard from "../../../../NewAmbassadorComponents/TransactionCard/TransactionCard";
import {
	getTransactionList3,
	transactionSearch,
	transactionSearchWithId,
} from "../../../../Services/Transactions";
import dayjs from "dayjs";
import SearchInput from "../../../../NewAmbassadorComponents/Form/SearchInput/SearchInput";
import TransactionSearchResult from "../TransactionSearch/TransactionSearch";
import FullScreenLoader from "../../../../NewAmbassadorComponents/Loader/FullScreenLoader";

const dropDownOptions2 = [
	{ label: "ALL", value: "ALL" },
	{ label: "SUCCESSFUL", value: "00" },
	{ label: "PROCESSING", value: "PP" },
	{ label: "FAILED", value: "01" },
	{ label: "ERROR OCCURED", value: "XX" },
	{ label: "INSUFFICIENT FUND", value: "51" },
	{ label: "ISSUER OR SWITCH INOPERATIVE", value: "91" },
	{ label: "DO NOT HONOUR", value: "05" },
];

const dropDownOptions = [
	{ label: "Today", value: "Today" },
	{ label: "1 Week", value: "1 Week" },
	{ label: "1 Month", value: "1 Month" },
	{ label: "Custom", value: "Custom" },
];

const AgentTransactionHistory = ({
	match: {
		params: { id },
	},
}) => {
	const [searchResultArray, setSearchResultArray] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [custom, setCustom] = useState(false);
	const [dateFilterValue, setdateFilterValue] = useState("Today");
	const [dateFilter, setdateFilter] = useState("Today");
	const [dateFilterLabel, setdateFilterLabel] = useState("Today");
	const [searchFilter, setSearchFilter] = useState(null);
	const [showSearchResult, setShowSearchResult] = useState(false);

	const [startDate, setStartDate] = useState(
		dayjs().subtract(7, "day").toDate()
	);
	const [endDate, setEndDate] = useState(dayjs().toDate());
	// console.log(startDate);

	const [dropDownValue, setDropDownValue] = useState("ALL");
	const [label, setLabel] = useState("ALL");

	const [searchValue, setSearchValue] = useState("");
	// const [searchBy, setSearchBy] = useState({ label: "Business Name", value: "businessName" });
	const [totalPages, setTotalPages] = useState(1);

	const { isFetching: isLoading2 } = useFetchWithParams(
		["agent-search3", { id, value: searchFilter }],
		transactionSearchWithId,
		{
			enabled: searchFilter !== null ? true : false,
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			onSuccess: (data) => {
				setSearchResultArray(data.data.data);
				setShowSearchResult(true);
			},
		}
	);

	const changePage = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
		// console.log(selected);
	};

	const _handleApplyClick = () => {
		const customFilter = `&startDateString=${dayjs(startDate).format(
			"YYYY-MM-DD"
		)}&endDateString=${dayjs(endDate).format("YYYY-MM-DD")}`;
		setdateFilter(customFilter);
	};

	const handleBackClick = () => {
		setSearchFilter(null);
		setShowSearchResult(false);
		setSearchValue("");
		setSearchResultArray((prev) => []);
		enableOverFlow();
	};

	const _handleGoClick = () => {
		if (searchValue.trim()) {
			const filter = `transactionId=${searchValue}`;
			setSearchFilter(filter);
		}
	};

	const _handleClear = () => {
		setSearchValue("");
		setSearchFilter(null);
	};

	const handleInputChange = (e) => {
		setSearchValue(e.target.value);
	};
	const _handleDropdownValue2 = (value) => {
		setCurrentPage(1);
		setDropDownValue((prev) => value);
	};

	const _handleDropdownValue = (value) => {
		setCurrentPage(1);
		if (value !== "Custom") {
			if (custom) {
				setCustom(false);
			}

			setdateFilterValue((prev) => value);
			setdateFilter((prev) => value);
		} else {
			setCustom(true);
			setdateFilterValue((prev) => value);
		}
	};

	const { isLoading, data, isSuccess } = useFetchWithParams(
		[
			"agent-list3",
			{ page: currentPage, id, dateFilter, filter: dropDownValue },
		],
		getTransactionList3,
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			refetchOnMount: true,
			onSuccess: (data) => {
				setTotalPages(data.data.recordsTotal ? data.data.recordsTotal : 0);
			},
		}
	);

	const hasData = isSuccess && data !== undefined && data.data.data.length > 0;
	const isEmpty =
		isSuccess && data !== undefined && data.data.data.length === 0;

	const showPagination = Math.ceil(totalPages / 30) > 1;

	const preventOverflow = () => {
		const bodyElement = document.querySelector("body");
		bodyElement.style.overflowY = "hidden";
	};

	const enableOverFlow = () => {
		const bodyElement = document.querySelector("body");
		bodyElement.style.overflowY = "scroll";
	};
	useEffect(() => {
		if (showSearchResult) {
			preventOverflow();
		} else {
			enableOverFlow();
		}
	}, [searchResultArray]);

	return (
		<div>
			<ScrollToTop />

			{isLoading2 && <FullScreenLoader />}
			{showSearchResult && (
				<TransactionSearchResult
					data={searchResultArray}
					backAction={handleBackClick}
				/>
			)}
			<div className="tw-flex tw-justify-between tw-items-center">
				<div
					onClick={() => {
						history.goBack();
					}}
					className="tw-flex tw-cursor-pointer tw-items-center"
				>
					<img
						src={require("../../../../assets/icons/back-arrow.svg")}
						className="tw-w-5 tw-mr-4"
						alt=""
					/>

					<span className="tw-text-black tw-font-bold">
						Transaction History
					</span>
				</div>
			</div>
			<div className="tw-mt-4 tw-justify-between tw-flex ">
				<div>
					<DurationDropdown
						value={dateFilterValue}
						label={dateFilterLabel}
						setFunction={_handleDropdownValue}
						setLabel={setdateFilterLabel}
						options={dropDownOptions}
						left="-90px"
					/>
				</div>
				<div>
					<DurationDropdown
						value={dropDownValue}
						label={label}
						setFunction={_handleDropdownValue2}
						setLabel={setLabel}
						options={dropDownOptions2}
					/>
				</div>
			</div>

			{custom && (
				<div className="tw-mt-6 tw-flex">
					<div>
						<DatePicker date={startDate} setDate={setStartDate} text="From" />
					</div>
					<div className="tw-ml-2">
						<DatePicker date={endDate} setDate={setEndDate} text="To" />
					</div>
					<div className="tw-flex-1 tw-self-end tw-ml-2">
						<button
							onClick={_handleApplyClick}
							className="tw-inline-block tw-w-full tw-bg-pc-secondaryshade1 tw-text-white tw-rounded-md tw-py-1 text-center focus:tw-outline-none tw-border-transparent"
						>
							Apply
						</button>
					</div>
				</div>
			)}
			<div className="tw-mt-6">
				<SearchInput
					onChange={handleInputChange}
					onClear={_handleClear}
					value={searchValue}
					onSubmit={_handleGoClick}
					placeholder="Search Transaction..."
				/>
			</div>

			<div className="tw-mt-10">
				{isEmpty && (
					<div
						style={{
							borderRadius: "10px",
							boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.04)",
						}}
						className="tw-h-24 tw-flex tw-justify-center tw-items-center tw-bg-white"
					>
						<p className="tw-text-pc-secondaryshade2 tw-mb-0 tw-font-semibold text-lg">
							No Transaction Found
						</p>
					</div>
				)}

				{isLoading &&
					Array(10)
						.fill(1)
						.map((each) => {
							return <Loader />;
						})}
				{hasData &&
					data.data.data.map((each, index) => {
						return (
							<TransactionCard key={`transactionHistory${index}`} data={each} />
						);
					})}

				{showPagination && (
					<div className="tw-mt-10">
						<NewPagination
							totalPages={totalPages > 1 ? Math.ceil(totalPages / 30) : 1}
							currentPage={currentPage}
							changePage={changePage}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default AgentTransactionHistory;

function Loader() {
	return (
		<NewAmbassadorCard addition={"tw-mb-5"}>
			<div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
				<div className="tw-flex tw-items-center">
					<span className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-200 tw-animate-pulse"></span>
					<span className="tw-font-bold pulse-loader tw-w-24 tw-text-pc-sm-mobile tw-text-black tw-ml-2"></span>
				</div>

				<span
					className={`pulse-loader tw-w-20 tw-px-2 tw-rounded tw-text-white tw-text-pc-btn2-mobile`}
				></span>
			</div>

			<div className="tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block pulse-loader tw-w-20 tw-text-pc-df-mobile tw-text-pc-grey9"></span>
					<p className="tw-font-bold tw-mt-2 tw-text-pc-h5-mobile pulse-loader tw-w-24 tw-mt-pc-6 tw-text-pc-secondaryshade2"></p>
				</div>
				<div className="tw-text-right">
					<span className="tw-block pulse-loader tw-w-20 tw-text-pc-df-mobile tw-text-pc-grey9"></span>
					<p className="tw-font-bold tw-mt-2 pulse-loader tw-w-12 tw-text-pc-h5-mobile tw-mt-pc-6 tw-text-pc-secondaryshade2"></p>
				</div>
			</div>

			<AmbassadorDivider addition="tw-my-2" />

			<div className="tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block pulse-loader tw-w-24 tw-text-pc-sm-mobile tw-text-pc-grey9"></span>
					<p className="tw-font-bold tw-mt-2 tw-text-pc-sm-mobile tw-flex tw-justify-between tw-items-center-pc-6 tw-text-black">
						<span className="pulse-loader tw-w-28"></span>
						{/* <img
							src={require("../../../../assets/icons/trans-id.svg")}
							alt=""
							className="w-3 ml-2"
						/> */}
					</p>
				</div>
				<div className="tw-text-right">
					<span className="tw-block tw-text-pc-sm-mobile pulse-loader tw-w-16 tw-text-pc-grey9"></span>
					<p className="tw-font-bold pulse-loader tw-mt-2 tw-w-24 tw-text-pc-sm-mobile tw-mt-pc-6 tw-text-black"></p>
				</div>
			</div>
		</NewAmbassadorCard>
	);
}
