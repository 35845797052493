export const statsDeterminant = (present, prev) => {
	if (present - prev !== 0) {
		return true;
	} else {
		return false;
	}
};

export const handleIncreaseOrDecrease = (present, prev) => {
	if (present - prev > 0) {
		return "increaseArrow";
	} else {
		return "decreaseArrow";
	}
};
