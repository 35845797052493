import React from "react";
import ScrollToTop from "../../../NewAmbassadorComponents/ScrollRestoration/ScrollRestoration";
import TransactionDash1 from "./TransactionDash1/TransactionDash1";
import TransactionDash2 from "./TransactionDash2/TransactionDash2";

const NewTransactionDashboard = () => {
	return (
		<div>
			<ScrollToTop />
			<TransactionDash1 />
			<TransactionDash2 />
		</div>
	);
};

export default NewTransactionDashboard;
