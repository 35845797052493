import { useQuery } from "react-query";

const useFetch = (key, apiFunction, config = {}) => {
	const fetchFunction = async () => {
		const response = await apiFunction();
		return response.data.data;
	};

	const data = useQuery(key, fetchFunction, config);

	// return {
	// 	loading: data.isLoading,

	// 	fetching: data.isFetching,

	// 	data: data,

	// 	refetch: data.refetch,
	// };

	return data;
};

export default useFetch;
