
const initialState={
                        errors:{},
                        values:{}
                    }

function updateValue(values, payload){
    return{
            ...values,
            [payload.name]:payload.value
    }
}



function form(state=initialState, action){
    const{payload} = action;
    switch(action.type){

        case 'UPDATE_FORM_FIELD':
            return{
                ...state,
                values: updateValue(state.values, action.payload)
            }
            break;
        

        default:
            return state;
        }
    return state;

    }


export default form;
