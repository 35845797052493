import React, {Component} from 'react';
import ServerError from '../../../../assets/img/server.svg';
import Button from '../../../../components/Button';
import './style.css';

class ErrorServer extends Component{
    render(){
        return (
            <div className='ErrorServer_wrap'>
                <div className='ErrorServer_innerwrap'>
                    <div className='ErrorServer_img'>
                        <img src={ServerError} />
                    </div>
                    <div>
                        <div className='error'>Error</div>
                        <div className='error_code'>500</div>
                    </div>
                    <div className='error_feedback'>
                        Sorry for the interruption, 
                        Please try again  later
                    </div>
    
                    <div className='error_button'>
                        <Button text='Reload' onClick={this._handleGoBack.bind(this)}/>
                    </div>
                </div>
                
            </div>
        )
    }

    _handleGoBack(){
        this.props.history.goBack();
    }
  
}

export default ErrorServer;
