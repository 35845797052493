import React from "react";
import Select from "react-select";
import { colourStyles, colourStyles2 } from "./SelectStyles";

const SelectInput = ({
	placeholder,
	onChange = () => {},
	onBlur = () => {},
	options = [],
	value = "",
	error = "",
	loading = false,
	bordered = true,

	name,
	...props
}: IS) => {
	return (
		<div>
			<Select
				{...props}
				isLoading={loading}
				closeMenuOnSelect={true}
				components={{
					IndicatorSeparator: () => null,
					ClearIndicator: () => null,
				}}
				name={name}
				onChange={onChange}
				onBlur={onBlur}
				placeholder={placeholder}
				value={value}
				isSearchable={false}
				isClearable
				// defaultValue={null}

				options={options}
				styles={bordered ? colourStyles : colourStyles2}
			/>
			{error && <span className="block mt-1 text-red-400 text-xs">{error}</span>}
		</div>
	);
};

export default SelectInput;

// (value) => {
//     console.log(value);
//     if (value === null) {
//         wallet.set("");
//     } else {
//         wallet.set(value);
//     }
// }
