import React, { useState, useRef } from "react";
import useOnClickOutside from "../../Hooks/useClickOutside";

const DurationDropdown = ({ options, value, setFunction, setLabel, label, left = "0px" }) => {
	const modalRef = useRef();

	const [isOpen, setIsOpen] = useState(false);
	const closeOptions = () => {
		setIsOpen(false);
	};
	useOnClickOutside(modalRef, closeOptions);
	const openOptions = () => {
		setIsOpen(true);
	};

	const handleClick = (newVal) => {
		// setFunction((prev) => newVal.value);
		setFunction(newVal.value);
		setLabel((prev) => newVal.label);
		closeOptions();
	};

	return (
		<div ref={modalRef} className="tw-relative tw-inline-block">
			<div
				style={{ backgroundColor: "#eaeaea" }}
				onClick={openOptions}
				className="tw-flex tw-items-center tw-rounded-md tw-px-2"
			>
				<span className="tw-text-pc-grey9 tw-text-pc-sm-mobile">{label}</span>
				<img
					src={require("../../assets/icons/chevron-down.svg")}
					className="tw-w-3 tw-ml-3"
					alt=""
				/>
			</div>

			{isOpen && (
				<ul
					style={{ top: "120%", width: "170px", right: left }}
					className="tw-bg-white tw-absolute pr-2 tw-shadow-md tw-z-10 tw-rounded tw-py-3"
				>
					{options.map((each, index) => {
						return (
							<li
								onClick={() => {
									handleClick(each);
								}}
								key={`dropDown_option_${index}`}
								className={`tw-py-2 tw-pl-3 tw-flex tw-items-center tw-border-b ${
									index !== options.length - 1
										? "tw-border-gray-100"
										: "tw-border-transparent"
								}`}
							>
								<span className="tw-w-4 tw-self-center tw-flex tw-items-center tw-bg-pc-secondaryshade2 tw-h-4 tw-rounded-full tw-justify-center">
									<span
										className={`tw-w-2 tw-h-2 tw-rounded-full ${
											value === each.value
												? "tw-bg-white"
												: "tw-bg-transparent"
										}`}
									></span>
								</span>
								<span
									className={`${
										value === each.value ? "tw-font-bold" : "tw-font-normal"
									} tw-text-pc-grey9 tw-flex-1 tw-text-xs tw-ml-5`}
								>
									{each.label}
								</span>
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default DurationDropdown;
