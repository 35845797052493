import axios from "axios";

const baseUrl = `https://paycentre.paypad.com.ng/api/v3/web/ambassador`;
// const baseUrl = `http://thor.paypad.com.ng:8000/api/v3/web/ambassador`;
// const baseUrl = `https://paycentre.paypad.com.nggapi/v3/web/ambassador`;
// const baseUrl = `https://paycentre.paypad.com.ng/api/v3`;
// const baseUrl = `http://thor.paypad.com.ng:8000/api/v3`;

const http = axios.create({
  baseURL: baseUrl,
});

http.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("amb_pc")) {
      const user = JSON.parse(localStorage.getItem("amb_pc"));
      const auth = {
        username: user.username,
        password: user.password,
      };
      config["auth"] = auth;
    }
    if (localStorage.getItem("user_pc")) {
      const user = JSON.parse(localStorage.getItem("user_pc"));
      const auth = {
        username: user.username,
        password: user.password,
      };
      config["auth"] = auth;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
