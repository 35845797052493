import React from "react";
import TransactionCard from "../../../../NewAmbassadorComponents/TransactionCard/TransactionCard";

const TransactionSearchResult = ({ backAction, data }) => {
	const hasData = data.length > 0;
	return (
		<section
			style={{ zIndex: 1000, backgroundColor: "#F4F5F6" }}
			className="tw-fixed tw-top-0 tw-overflow-y-auto tw-right-0 tw-left-0 tw-bottom-0 tw-w-screen tw-h-screen"
		>
			<div className="global-container-amb tw-bg-white tw-flex tw-items-center tw-h-16">
				<div onClick={backAction} className="tw-flex tw-cursor-pointer tw-items-center">
					<img
						src={require("../../../../assets/icons/back-arrow.svg")}
						className="tw-w-5 tw-mr-4"
						alt=""
					/>

					<span className="tw-text-black tw-font-bold">Back</span>
				</div>
			</div>
			<div className="tw-pt-10 agent-search-result-height tw-overflow-y-auto tw-pb-20">
				<div className="global-container-amb">
					<h4 className="tw-text-black tw-font-bold tw-pb-3 tw-text-pc-df-desktop tw-text-center">
						Search Result(s)
					</h4>
					{!hasData && (
						<p className="tw-text-black tw-font-bold tw-pb-3 tw-mt-8 tw-text-pc-df-desktop tw-text-center">
							No Match Found
						</p>
					)}
					{hasData &&
						data.map((each, index) => {
							return (
								<TransactionCard
									key={`transactionsList${index}`}
									data={each}
									// color="tw-bg-green-500"
									// status="Successful"
								/>
							);
						})}
				</div>
			</div>
		</section>
	);
};

export default TransactionSearchResult;
