import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "../../utils/formatNumber";
const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const isAllowedToWithdraw = (data) => {
	const currentMonth = new Date().getMonth() + 1;
	const _year = new Date().getFullYear();

	if (data.year < _year) {
		return true;
	}
	if (data.year > _year) {
		return false;
	}
	if (_year === data.year) {
		if (currentMonth === data.month) {
			return false;
		}
		if (
			dayjs().isAfter(dayjs(`${data.year}-${data.month}-03`).add(1, "month"))
		) {
			return true;
		} else {
			return false;
		}
	}
};

const CommissionCard = ({
	data,
	openNoticeModal,
	index,
	borderBottom = true,
}) => {
	const shouldWithdraw = isAllowedToWithdraw(data);
	return (
		<div
			style={{
				// borderColor: "#DEF1FF",
				borderRadius: "10px",
				boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.04)",
			}}
			className={`tw-bg-white tw-flex tw-justify-between ${
				borderBottom ? "tw-mb-3" : ""
			} tw-items-center tw-p-1 tw-px-3`}
		>
			<div>
				<span className="tw-block pc-font-regular tw-text-pc-df-mobile tw-text-pc-grey9 tw-mt-2">
					{monthNames[(data.month - 1).toString()]} {data.year}
				</span>
				<h6 className="tw-font-bold pc-font-bold tw-text-pc-h5-mobile tw-mt-1 tw-text-black">
					₦{formatNumber(data.amount)}
				</h6>
			</div>

			{data.status === "not-collected" && (
				<>
					{!shouldWithdraw && (
						<button
							style={{ fontSize: "13px" }}
							onClick={openNoticeModal}
							// to="/ambassador/test11"
							className={`tw-bg-pc-secondaryshade2 tw-cursor-not-allowed tw-px-3 tw-rounded-md  focus:tw-outline-none tw-text-white tw-flex pc-font-bold tw-text-xs tw-items-center tw-justify-center tw-text-center tw-py-2 focus:tw-text-white focus:tw-no-underline`}
						>
							Withdraw
						</button>
					)}
					{shouldWithdraw && (
						<Link
							style={{ fontSize: "13px" }}
							to="/ambassador/withdraw-commission"
							className={`tw-bg-pc-secondaryshade2 tw-cursor-not-allowed tw-px-3 tw-rounded-md  focus:tw-outline-none tw-text-white tw-flex pc-font-bold tw-text-xs tw-items-center tw-justify-center tw-text-center tw-py-2 focus:tw-text-white focus:tw-no-underline`}
						>
							Withdraw
						</Link>
					)}
				</>
			)}
			{data.status === "collected" && (
				<span
					style={{ background: "#eaeaea", fontSize: "13px" }}
					className={`tw-text-pc-grey9 tw-px-3 tw-rounded-md tw-cursor-pointer focus:tw-outline-none tw-flex pc-font-bold tw-items-center tw-justify-center tw-text-center tw-py-2 focus:tw-text-white focus:tw-no-underline`}
				>
					Collected
				</span>
			)}
		</div>
	);
};

export default CommissionCard;
