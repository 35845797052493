import React, { useState } from "react";
import { history } from "../../../App";
import SuccessModal from "../../../NewAmbassadorComponents/SuccessModal/SuccessModal";
// import AgentConfirmationModal from "./AgentConfirmationModal/AgentConfirmationModal";
// import ConfirmWithdrawPage from "./ConfirmWithdrawPage/ConfirmWithdrawPage";
import WithdrawPage from "./WithdrawPage/WithdrawPage";

const WithdrawCommission = () => {
	// const [currentPage, setCurrentPage] = useState("withdraw page");
	// const [withdrawPageData, setWithdrawPageData] = useState({});
	// const [showConfirmModal, setShowConfirmModal] = useState(false);
	// const [showSuccessModal, setShowSuccessModal] = useState(false);




	// const openSuccessModal = (params) => {
	// 	setShowSuccessModal(true);
	// };

	// const handleContinueClick = () => {
	// 	console.log("clicked");
	// 	const protocol = window.location.protocol;
	// 	const host = window.location.host;
	// 	const url = `${protocol}//${host}/ambassador/dashboard`;
	// 	console.log(url);
	// 	window.location.href = url;
	// 	// history.push("/ambassador/test1");
	// };

	

	return (
		<>
			{/* {showSuccessModal && (
				<SuccessModal
					content="Your withdrawal was successful"
					onButtonClick={handleContinueClick}
					buttonText="Continue"
				/>
			)} */}
			{/* {showConfirmModal && (
				<AgentConfirmationModal
					content="Adebalogun Fatai Olawale"
					buttonText="Cancel"
					agentId="Alase"
					onCancelClick={handleConfirmCancel}
					onProceedClick={handleProceed}
				/>
			)} */}
			
				<WithdrawPage  />
			
			{/* {currentPage === "confirm withdraw" && (
				<ConfirmWithdrawPage
					data={withdrawPageData}
					goBack={handleGoBack}
					handleSuccess={openSuccessModal}
				/>
			)} */}
		</>
	);
};

export default WithdrawCommission;
