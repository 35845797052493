import React from "react";
import AmbassadorArrow from "../../../../NewAmbassadorComponents/Arrow/AmbassadorArrow";
import NewAmbassadorCard from "../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import AmbassadorDivider from "../../../../NewAmbassadorComponents/Divider/AmbassadorDivider";
import useFetch from "../../../../Hooks/useFetch";
import { getAgentSignup } from "../../../../Services/Dashboard";
import { statsDeterminant, handleIncreaseOrDecrease } from "../../../../utils/helpers";

const DashCard2 = () => {
	const { data, isSuccess, isLoading } = useFetch("agent-breakdown", getAgentSignup);

	const hasData = isSuccess && data !== undefined;

	return (
		<>
			{isLoading && <Loader />}
			{hasData && (
				<NewAmbassadorCard addition="tw-mt-8">
					<span className="tw-block tw-text-pc-df-mobile tw-text-pc-grey9 tw-mt-2">
						My Agents
					</span>
					<h6 className="tw-font-bold tw-text-pc-h4-desktop tw-mt-pc-8 tw-text-black">
						{data.allTimeSignUp}
					</h6>
					<AmbassadorDivider addition="tw-my-3" />

					<div className="tw-mb-1 tw-flex tw-justify-between tw-items-center">
						<div>
							<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
								This Month
							</span>
							<div className="tw-flex tw-justify-center tw-items-center">
								<p className="tw-font-bold tw-mb-0 tw-mr-3 tw-text-pc-df-mobile tw-text-black">
									{data.signUpForCurrentMonth}
								</p>

								{statsDeterminant(
									data.signUpForCurrentMonth,
									data.signUpForPreviousMonth
								) && (
									<AmbassadorArrow
										type={handleIncreaseOrDecrease(
											data.signUpForCurrentMonth,
											data.signUpForPreviousMonth
										)}
									/>
								)}
							</div>
						</div>
						{/* <div>
							<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
								Inactive
							</span>
							<div className="tw-flex tw-justify-center tw-items-center">
								<p className="tw-font-bold tw-mb-0 tw-mr-3 tw-text-pc-df-mobile tw-text-black">
									6
								</p>
								<AmbassadorArrow type="decreaseArrow" />
							</div>
						</div> */}
						<div>
							<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
								Daily Sign Up
							</span>
							<div className="tw-flex tw-justify-center tw-items-center">
								<p className="tw-font-bold tw-mb-0 tw-mr-3 tw-text-pc-df-mobile tw-text-black">
									{data.signUpForCurrentDay}
								</p>
								{statsDeterminant(
									data.signUpForCurrentDay,
									data.signUpForPreviousDay
								) && (
									<AmbassadorArrow
										type={handleIncreaseOrDecrease(
											data.signUpForCurrentDay,
											data.signUpForPreviousDay
										)}
									/>
								)}
							</div>
						</div>
					</div>
				</NewAmbassadorCard>
			)}
		</>
	);
};

export default DashCard2;

function Loader() {
	return (
		<NewAmbassadorCard addition="tw-mt-8">
			<span className="tw-block pulse-loader tw-w-32 tw-text-pc-df-mobile tw-text-pc-grey9 tw-mt-2"></span>
			<span className="tw-font-bold tw-block pulse-loader tw-w-16 tw-text-pc-h4-desktop tw-mt-pc-8 tw-text-pc-secondaryshade2"></span>
			<AmbassadorDivider addition="tw-my-3" />

			<div className="tw-mb-1 tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block pulse-loader tw-w-32 tw-text-pc-sm-mobile tw-text-pc-grey9"></span>
					<div className="tw-flex tw-mt-2 tw-items-center">
						<p className="tw-font-bold pulse-loader tw-w-16 mb-0 tw-mr-3 tw-text-pc-df-mobile tw-text-black"></p>
						{/* <AmbassadorArrow /> */}
					</div>
				</div>
				{/* <div>
			<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">Inactive</span>
			<div className="tw-flex tw-justify-center tw-items-center">
				<p className="tw-font-bold tw-mb-0 tw-mr-3 tw-text-pc-df-mobile tw-text-black">
					6
				</p>
				<AmbassadorArrow type="decreaseArrow" />
			</div>
		</div> */}
				<div>
					<span className="tw-block tw-text-pc-sm-mobile pulse-loader tw-w-24 tw-text-pc-grey9"></span>
					<div className="tw-flex tw-mt-2 tw-justify-center tw-items-center">
						<p className="tw-font-bold tw-mb-0 tw-mr-3 pulse-loader tw-w-16 tw-text-pc-df-mobile tw-text-black"></p>
						{/* <AmbassadorArrow /> */}
					</div>
				</div>
			</div>
		</NewAmbassadorCard>
	);
}
