import React from "react";
import { Link } from "react-router-dom";

const GlobalButton = ({
	type = "link",
	addition,
	to,
	action = () => {},
	label,
	disabled = false,
}) => {
	return type === "link" ? (
		<Link
			to={to}
			className={`${addition} hover:tw-no-underline hover:tw-text-white tw-rounded-md tw-cursor-pointer focus:tw-outline-none tw-text-white tw-flex pc-font-regular tw-items-center tw-justify-center tw-text-center tw-py-2 focus:tw-text-white focus:tw-no-underline`}
		>
			{label}
		</Link>
	) : (
		<button
			onClick={action}
			disabled={disabled}
			className={`${addition} tw-rounded-md tw-cursor-pointer focus:tw-outline-none tw-text-white tw-flex pc-font-regular tw-items-center tw-justify-center tw-text-center tw-py-1`}
		>
			{label}
		</button>
	);
};

export default GlobalButton;
