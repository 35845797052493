import React from "react";
import { Link } from "react-router-dom";

const SidebarItems = ({ to, onClick, label, addMargin, index, isActive, component: Component }) => {
  return (
    <Link
      onClick={onClick}
      className={`${addMargin ? "mb-4" : ""} tw-flex hover:tw-outline-none tw-items-center`}
      to={to}
    >
      <Component
        className={`tw-w-5 ${isActive ? "tw-text-pc-secondaryshade2" : "tw-text-pc-grey5"}`}
      />
      <span
        className={`ml-3 tw-text-pc-df-mobile ${isActive
            ? "tw-font-bold tw-text-pc-secondaryshade2"
            : "tw-font-normal tw-text-pc-grey9"
          } `}
      >
        {label}
      </span>
    </Link>
  );
};

export default SidebarItems;
