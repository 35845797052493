import http from "./Axios-Config";
export const getAgentsBreakdown = async (value = "") => {
	return await http.get("/agents/group");
};
export const getAgentsList = async (filter) => {
	let url;
	if (filter === 0) {
		url = `agent/filter?draw=0&startPage=0&length=6`;
	} else {
		url = `agent/filter?draw=0&startPage=0&length=6&agentGroupId=${filter}`;
	}
	return await http.get(url);
};
export const agentSearch = async (filter) => {
	let _filter;
	if (filter) {
		_filter = filter;
	} else {
		_filter = "iii";
	}
	return await http.get(`agent/filter?draw=0&startPage=0&length=50&${_filter}`);
};
export const agentSearch2 = async (page, filter) => {
	let url;
	if (filter === 0) {
		url = `agent/filter?draw=0&startPage=${page - 1}&length=30`;
	} else {
		url = `agent/filter?draw=0&startPage=${page - 1}&length=30&agentGroupId=${filter}`;
	}
	return await http.get(url);
};
