import React, { Component, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import Loader from "./components/mainLoader";

import SideNav from "./components/sideNav";
import NonFound from "./views/pages/errorPages/error404";
import ServerError from "./views/pages/errorPages/error500";
// import ErrorBoundary from "./components/errorBoundary";

import "./App.css";
import Test from "./views/ambassadorPages/TestFile/Test";
import NewAmbassadorLayout from "./views/templates/NewAmbassadorLayout/NewLayout";
import NewAmbassadorDashboard from "./views/NewAmbassadorPages/Dashboard/NewDashboard";
import NewAgentDash from "./views/NewAmbassadorPages/Agents/NewAgentDash";
import AllAgents from "./views/NewAmbassadorPages/AllAgents/AllAgents";
import NewTransactionDashboard from "./views/NewAmbassadorPages/Transaction/NewTransactionDashboard";
import AllTransactions from "./views/NewAmbassadorPages/AllTransactions/AllTransactions";
// import ActivationCode from "./views/NewAmbassadorPages/ActivationCode/ActivationCode";
import CommissionHistory from "./views/NewAmbassadorPages/CommissionHistory/CommissionHistory";
// import WithdrawCommission from "./views/NewAmbassadorPages/WithdrawCommission/WithdrawCommission";
import AmbassadorGuard from "./Guards/AmbassadorGuard";
import AgentTransactionHistory from "./views/NewAmbassadorPages/Transaction/AgentTransactionHistory/AgentTransactionHistory";
import ErrorBoundary from "./NewAmbassadorComponents/ErrorBoundary/ErrorBoundary";
import WithdrawCommission from "./views/NewAmbassadorPages/WithdrawCommission/WithdrawCommission";
import GenerateReconciliationCode from "./views/NewAmbassadorPages/SupervisorCode/GenerateReconciliationCode";
// import bvnUpdate from "./views/pages/bvnUpdate";

const Dashboard = lazy(() => import("./views/pages/dashboard"));
const Login = lazy(() => import("./views/pages/login"));
const successPassword = lazy(() => import("./views/pages/passwordChanged"));
const successBvn = lazy(() => import("./views/pages/bvnChanged"));
const bvnUpdate = lazy(() => import("./views/pages/bvnUpdate"));
const ChangePassword = lazy(() => import("./views/pages/changePassword"));
const ForgotPassword = lazy(() => import("./views/pages/forgotPassword"));
const PosCreate = lazy(() => import("./views/pages/createPOS"));
const Transactions = lazy(() => import("./views/pages/transactions"));
const ReactivateAgents = lazy(() => import("./views/pages/reactivateAgents"));
// const AmbassadorDashboard = lazy(() =>
// 	import("./views/ambassadorPages/ambassadorDashboard")
// );
// const AmbassadorTransactions = lazy(() =>
// 	import("./views/ambassadorPages/ambassadorTransactions")
// );
// const AmbassadorProfile = lazy(() =>
// 	import("./views/ambassadorPages/ambassadorProfile")
// );
// const AllAmbassadorAgents = lazy(() =>
// 	import("./views/ambassadorPages/allAmbassadorAgents")
// );
// const passwordChangeDone = lazy(() =>
// 	import("./views/ambassadorPages/AmbassadorPaswordChanged")
// );
// const AmbassadorChangePassword = lazy(() =>
// 	import("./views/ambassadorPages/ambassadorChangePassword")
// );

export const history = createBrowserHistory();

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router history={history}>
          <Suspense
            fallback={
              <Loader type="Oval" height={60} width={60} color="#1E4A86" />
            }
          >
            <Switch>
              {/* <p className="tw-bg-red-500 h-screen">sodiq</p> */}
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/poscreate" component={PosCreate} />
              <Route exact path="/transactions" component={Transactions} />
              <Route
                exact
                path="/reactivate-agents"
                component={ReactivateAgents}
              />
              <Route exact path="/bvn" component={bvnUpdate} />
              <Route exact path="/changePassword" component={ChangePassword} />
              <Route
                exact
                path="/successPassword"
                component={successPassword}
              />
               <Route
                exact
                path="/successBvn"
                component={successBvn}
              />
              {/* <Route
								exact
								path="/ambassador/dashboard"
								component={AmbassadorDashboard}
							/>
							<Route exact path="/ambassador/profile" component={AmbassadorProfile} />
							<Route
								exact
								path="/ambassador/transac"
								component={AmbassadorTransactions}
							/>
							<Route
								exact
								path="/ambassador/allagents"
								component={AllAmbassadorAgents}
							/>
							<Route
								exact
								path="/ambassador/passwordDone"
								component={passwordChangeDone}
							/>
							<Route
								exact
								path="/ambassador/passwordChange"
								component={AmbassadorChangePassword}
							/> */}

              <AmbassadorGuard
                path={[
                  "/ambassador/dashboard",
                  "/ambassador/agents",
                  "/ambassador/all-agents",
                  "/ambassador/transactions",
                  "/ambassador/all-transactions",
                  "/ambassador/commission-history",
                  "/ambassador/agent-transaction-history/:id",
                  "/ambassador/withdraw-commission",
                  "/ambassador/generate-reconciliation-code",
                ]}
              >
                <ErrorBoundary>
                  <NewAmbassadorLayout>
                    <Route
                      exact
                      path="/ambassador/dashboard"
                      component={NewAmbassadorDashboard}
                    />
                    <Route
                      exact
                      path="/ambassador/agents"
                      component={NewAgentDash}
                    />
                    <Route
                      exact
                      path="/ambassador/all-agents"
                      component={AllAgents}
                    />
                    <Route
                      exact
                      path="/ambassador/transactions"
                      component={NewTransactionDashboard}
                    />
                    <Route
                      exact
                      path="/ambassador/all-transactions"
                      component={AllTransactions}
                    />

                    <Route
                      exact
                      path="/ambassador/commission-history"
                      component={CommissionHistory}
                    />

                    <Route
                      exact
                      path="/ambassador/agent-transaction-history/:id"
                      component={AgentTransactionHistory}
                    />
                    <Route
                      exact
                      path="/ambassador/withdraw-commission"
                      component={WithdrawCommission}
                    />
                    <Route
                      exact
                      path="/ambassador/generate-reconciliation-code"
                      component={GenerateReconciliationCode}
                    />
                  </NewAmbassadorLayout>
                </ErrorBoundary>
              </AmbassadorGuard>

              <Route exact path="/error" component={ServerError} />
              <Route path="*" component={NonFound} />
              <Route component={SideNav} />
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }
}

export default App;
