import React, { useState, useEffect } from "react";
import useFetch from "../../../../Hooks/useFetch";
import useFetchWithParams from "../../../../Hooks/useFetchWithParams";
import GlobalButton from "../../../../NewAmbassadorComponents/Buttons/GlobalButton";
import NewAmbassadorCard from "../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import AmbassadorDivider from "../../../../NewAmbassadorComponents/Divider/AmbassadorDivider";
import DurationDropdown from "../../../../NewAmbassadorComponents/DurationDropdown/DurationDropdown";
import SearchInput from "../../../../NewAmbassadorComponents/Form/SearchInput/SearchInput";
import SearchInputWithSelect from "../../../../NewAmbassadorComponents/Form/SearchInput/SearchInputWithSelect";
import FullScreenLoader from "../../../../NewAmbassadorComponents/Loader/FullScreenLoader";
import SelectInput from "../../../../NewAmbassadorComponents/SelectInput.js/SelectInput";
import TransactionCard from "../../../../NewAmbassadorComponents/TransactionCard/TransactionCard";
import {
	getTransactionsList,
	transactionSearch,
} from "../../../../Services/Transactions";
import TransactionSearchResult from "../TransactionSearch/TransactionSearch";

const dropDownOptions = [
	{ label: "ALL", value: "ALL" },
	{ label: "SUCCESSFUL", value: "00" },
	{ label: "PROCESSING", value: "PP" },
	{ label: "FAILED", value: "01" },
	{ label: "ERROR OCCURED", value: "XX" },
	{ label: "INSUFFICIENT FUND", value: "51" },
	{ label: "ISSUER OR SWITCH INOPERATIVE", value: "91" },
	{ label: "DO NOT HONOUR", value: "05" },
];

const searchOptions = [
	// {
	// 	label: "User ID",
	// 	value: "user Id",
	// },
	{
		label: "Transaction ID",
		value: "transactionId",
	},
	{
		label: "RRN",
		value: "rrn",
	},
	{
		label: "PAN",
		value: "pan",
	},
	{
		label: "STAN",
		value: "stan",
	},
];

const TransactionDash2 = () => {
	const [searchResultArray, setSearchResultArray] = useState([]);
	const [searchBy, setSearchBy] = useState({
		label: "Transaction ID",
		value: "transactionId",
	});
	const [searchValue, setSearchValue] = useState("");
	const [searchFilter, setSearchFilter] = useState(null);
	const [showSearchResult, setShowSearchResult] = useState(false);
	const [dropDownValue, setDropDownValue] = useState("ALL");
	const [label, setLabel] = useState("ALL");

	// const { data, isLoading, isSuccess } = useFetch(
	// 	"transaction-list-truncated",
	// 	getTransactionsList
	// );

	const { isLoading, data, isSuccess } = useFetchWithParams(
		["transaction-list-truncated", { agentFilter: dropDownValue }],
		getTransactionsList
		// {
		// 	keepPreviousData: false,
		// 	refetchOnWindowFocus: false,
		// 	refetchOnMount: true,
		// 	onSuccess: (data) => {
		// 		setTotalPages(data.data.recordsTotal);
		// 	},
		// }
	);

	const { isFetching: isLoading2 } = useFetchWithParams(
		["transaction-search1", { value: searchFilter }],
		transactionSearch,
		{
			enabled: searchFilter !== null ? true : false,
			// enabled: searchRef.current,
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			onSuccess: (data) => {
				// console.log(data);
				setSearchResultArray(data.data.data);
				setShowSearchResult(true);
			},
		}
	);

	const hasData = isSuccess && data !== undefined && data.data.data.length > 0;
	const isEmpty =
		isSuccess && data !== undefined && data.data.data.length === 0;
	const handleBackClick = () => {
		setSearchFilter(null);

		setShowSearchResult(false);
		setSearchValue("");
		setSearchResultArray((prev) => []);
		enableOverFlow();
	};

	const handleInputChange = (e) => {
		// console.log("changing");
		setSearchValue(e.target.value);
	};

	const _handleSearchByChange = (val) => {
		setSearchBy((prev) => val);
	};

	const _handleClear = () => {
		setSearchValue("");
		setSearchFilter(null);
	};

	const _handleGoClick = () => {
		console.log("value", searchValue);
		if (searchBy.value && searchValue.trim()) {
			console.log("can Search");
			const filter = `${searchBy.value}=${searchValue}`;
			setSearchFilter(filter);
		}
	};

	const _handleDropdownValue = (value) => {
		setDropDownValue((prev) => value);
	};
	const preventOverflow = () => {
		const bodyElement = document.querySelector("body");
		bodyElement.style.overflowY = "hidden";
	};

	const enableOverFlow = () => {
		const bodyElement = document.querySelector("body");
		bodyElement.style.overflowY = "scroll";
	};
	useEffect(() => {
		if (showSearchResult) {
			preventOverflow();
		} else {
			enableOverFlow();
		}
	}, [searchResultArray]);
	// useEffect(() => {

	// }, []);

	return (
		<div className="tw-mt-8 tw-pb-8">
			{showSearchResult && (
				<TransactionSearchResult
					data={searchResultArray}
					backAction={handleBackClick}
				/>
			)}

			{isLoading2 && <FullScreenLoader />}

			<div className="tw-flex tw-justify-between tw-items-center">
				<p className="tw-font-bold tw-mb-0 tw-text-black tw-text-pc-df-desktop">
					Transactions
				</p>

				<DurationDropdown
					value={dropDownValue}
					label={label}
					setFunction={_handleDropdownValue}
					setLabel={setLabel}
					options={dropDownOptions}
				/>
			</div>
			<div className="tw-mt-6">
				<SearchInputWithSelect
					options={searchOptions}
					selectValue={searchBy}
					selectChange={_handleSearchByChange}
					onChange={handleInputChange}
					onClear={_handleClear}
					value={searchValue}
					onSubmit={_handleGoClick}
					placeholder="Search Transac..."
					selectPlaceholder="Search By.."
				/>
			</div>

			<div className="tw-mt-10">
				{isLoading &&
					Array(10)
						.fill(0)
						.map((each) => {
							return <Loader />;
						})}

				{hasData &&
					data.data.data.map((each, index) => {
						return (
							<TransactionCard
								key={`transactionsList${index}`}
								data={each}
								// color="tw-bg-green-500"
								// status="Successful"
							/>
						);
					})}
				{isEmpty && (
					<div
						style={{
							borderRadius: "10px",
							boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.04)",
						}}
						className="tw-h-24 tw-flex tw-justify-center tw-items-center tw-bg-white"
					>
						<p className="tw-text-pc-secondaryshade2 tw-mb-0 tw-font-semibold text-lg">
							No Transaction Found
						</p>
					</div>
				)}

				{/* <TransactionCard color="tw-bg-green-500" status="Successful" />
				<TransactionCard color="tw-bg-green-500" status="Successful" />
				<TransactionCard color="tw-bg-yellow-500" status="Pending" />
				<TransactionCard color="tw-bg-green-500" status="Successful" />
				<TransactionCard color="tw-bg-yellow-500" status="Pending" />
				<TransactionCard
					color="tw-bg-green-500"
					status="Successful"
					marginBottom="tw-mb-0"
				/> */}

				<div className="tw-mt-12 tw-flex tw-justify-center">
					<GlobalButton
						to="/ambassador/all-transactions"
						label="View All Transactions"
						addition="tw-px-8 tw-bg-pc-secondaryshade2"
					/>
				</div>
			</div>
		</div>
	);
};

export default TransactionDash2;

function Loader() {
	return (
		<NewAmbassadorCard addition={"tw-mb-5"}>
			<div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
				<div className="tw-flex tw-items-center">
					<span className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-200 tw-animate-pulse"></span>
					<span className="tw-font-bold pulse-loader tw-w-24 tw-text-pc-sm-mobile tw-text-black tw-ml-2"></span>
				</div>

				<span
					className={`pulse-loader tw-w-20 tw-px-2 tw-rounded tw-text-white tw-text-pc-btn2-mobile`}
				></span>
			</div>

			<div className="tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block pulse-loader tw-w-20 tw-text-pc-df-mobile tw-text-pc-grey9"></span>
					<p className="tw-font-bold tw-mt-2 tw-text-pc-h5-mobile pulse-loader tw-w-24 tw-mt-pc-6 tw-text-pc-secondaryshade2"></p>
				</div>
				<div className="tw-text-right">
					<span className="tw-block pulse-loader tw-w-20 tw-text-pc-df-mobile tw-text-pc-grey9"></span>
					<p className="tw-font-bold tw-mt-2 pulse-loader tw-w-12 tw-text-pc-h5-mobile tw-mt-pc-6 tw-text-pc-secondaryshade2"></p>
				</div>
			</div>

			<AmbassadorDivider addition="tw-my-2" />

			<div className="tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block pulse-loader tw-w-24 tw-text-pc-sm-mobile tw-text-pc-grey9"></span>
					<p className="tw-font-bold tw-mt-2 tw-text-pc-sm-mobile tw-flex tw-justify-between tw-items-center-pc-6 tw-text-black">
						<span className="pulse-loader tw-w-28"></span>
						{/* <img
							src={require("../../../../assets/icons/trans-id.svg")}
							alt=""
							className="w-3 ml-2"
						/> */}
					</p>
				</div>
				<div className="tw-text-right">
					<span className="tw-block tw-text-pc-sm-mobile pulse-loader tw-w-16 tw-text-pc-grey9"></span>
					<p className="tw-font-bold pulse-loader tw-mt-2 tw-w-24 tw-text-pc-sm-mobile tw-mt-pc-6 tw-text-black"></p>
				</div>
			</div>
		</NewAmbassadorCard>
	);
}
