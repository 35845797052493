import React from "react";

const HamburgerMenu = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`tw-w-5 tw-h-3 mobiles:tw-block harmburger-amb tw-relative tw-cursor-pointer`}
		>
			<span className="tw-w-full tw-rounded-sm line1 tw-absolute tw-block"></span>
			<span className="tw-w-full tw-rounded-sm ml-auto line2 tw-absolute tw-block"></span>
			<span className="tw-w-full tw-rounded-sm tw-absolute line3 tw-block"></span>
		</div>
	);
};

export default HamburgerMenu;
