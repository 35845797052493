import { duration } from "moment";
import React from "react";
import NewAmbassadorCard from "../../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import { formatNumber } from "../../../../../utils/formatNumber";

const AmbassadorPerformanceList = ({ type = "top", list }) => {
	const isEmpty = list.length === 0;
	return (
		<ul>
			{isEmpty && (
				<div className="tw-text-center tw-font-semibold tw-text-pc-secondaryshade2 tw-pb-4">
					No Record Found
				</div>
			)}
			{/* <DurationDropdown/> */}
			{list.map((each, index) => {
				return (
					<li
						key={`performanceList${index}`}
						className={`tw-py-2 tw-flex tw-items-center tw-justify-between tw-border-b ${
							index !== list.length - 1
								? "tw-border-gray-100"
								: "tw-border-transparent"
						}`}
					>
						<span className="tw-text-pc-sm-mobile tw-text-pc-grey9">
							{(each.businessName || each.business_name).toUpperCase()}
						</span>
						<span
							className={`tw-text-pc-sm-mobile ${
								type === "top" ? "tw-text-green-500" : "tw-text-red-500"
							} tw-font-bold`}
						>
							₦
							{
								formatNumber(each.transactionValue || each.value)
								// (each.transactionValue || each.value)
								// 	.toFixed(2)
								// 	.toFixed(2)
								// 	.toLocaleString()
							}
						</span>
					</li>
				);
			})}
		</ul>
	);
};

export default AmbassadorPerformanceList;
