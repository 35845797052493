import http from "./Axios-Config";
import dayjs from "dayjs";

export const getTransactionsList = async (filter) => {
	let url;
	if (filter === "ALL") {
		url = "transaction/filter?draw=0&length=6&startPage=0";
	} else {
		url = `transaction/filter?draw=0&length=6&startPage=0&status=${filter}`;
	}
	return await http.get(url);
};

export const getTransactionList2 = async (page, filter) => {
	let url;
	if (filter === "ALL") {
		url = `transaction/filter?draw=0&length=30&startPage=${page - 1}`;
	} else {
		url = `transaction/filter?draw=0&length=30&startPage=${page - 1}&status=${filter}`;
	}
	return await http.get(url);
};
export const getTransactionList3 = async (page, id, dateFilter, statusFilter) => {
	let _dateFilter;
	if (dateFilter === "Today") {
		_dateFilter = `&startDateString=${dayjs().format("YYYY-MM-DD")}`;
	} else if (dateFilter === "1 Week") {
		_dateFilter = `&startDateString=${dayjs().subtract(7, "day").format("YYYY-MM-DD")}`;
	} else if (dateFilter === "1 Month") {
		_dateFilter = `&startDateString=${dayjs().subtract(1, "month").format("YYYY-MM-DD")}`;
	} else {
		_dateFilter = dateFilter;
	}

	let url;
	if (statusFilter === "ALL") {
		url = _dateFilter;
	} else {
		url = `${_dateFilter}&status=${statusFilter}`;
	}

	return await http.get(
		`transaction/filter?draw=0&startPage=${page - 1}&length=30&userId=${id}${url}`
	);
};

export const transactionSearch = async (filter) => {
	let _filter;
	if (filter) {
		_filter = filter;
	} else {
		_filter = "iii";
	}
	return await http.get(`transaction/filter?draw=0&startPage=0&length=50&${_filter}`);
};

export const transactionSearchWithId = async (id, filter) => {
	let _filter;
	if (filter) {
		_filter = filter;
	} else {
		_filter = "iii";
	}
	return await http.get(
		`transaction/filter?draw=0&startPage=0&length=50&userId=${id}&${_filter}`
	);
};
