import http from "./Axios-Config";
export const getPerformingAgents = async (value = "") => {
  const url = value
    ? `/agents/transactions/top/least/${value}`
    : `/agents/transactions/top/least`;

  return await http.get(url);
};
export const getLeadingAmbassadors = async () => {
  return await http.get("/leaderboard");
};

export const getAgentWalletDetails = async () => {
  return await http.get("/wallet/details");
};
export const getAgentSignup = async () => {
  return await http.get("/agents/signup");
};
export const getAgentTransactionBreakdown = async () => {
  return await http.get("/agents/transaction/breakdown");
};
export const getTransactionMonitorData = async () => {
  return await http.get("https://up.paypad.com.ng/bin/status");
};
export const getAgentDetails = async (memberId) => {
  return await http.get(`/agent/validate/memberId?memberId=${memberId}`);
};
export const reactivateAgent = async (payload) => {
  return await http.post(`/inactive/agent/reactivate`, payload);
};
export const getReactivatedAgents = async (assigneeId) => {
  return await http.get(`/inactive/agent/tracker`);
};
