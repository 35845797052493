import React, { useState } from "react";
import GlobalButton from "../../../NewAmbassadorComponents/Buttons/GlobalButton";
import GenericModal from "../../../NewAmbassadorComponents/GenericModal/GenericModal";
import Spinner from "../../../components/Spinner";
import { generateSupervisorCode } from "../../../Services/ReconciliationCode";

const GenerateReconciliationCode = () => {
  const [loading, setLoading] = useState(false);
  const [openGenericModal, setOpenGenericModal] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [reconciliationCode, setReconciliationCode] = useState("");

  const resetState = () => {
    setOpenGenericModal(false);
    setModalMsg("");
    setReconciliationCode("");
  };


  const handleFormSubmit = async (e) => {
    try {
      setLoading(true);
      const res = await generateSupervisorCode();
      if (res.data.responseCode === "00") {
        setOpenGenericModal(true);
        setModalMsg(res.data.responseMessage);
        setReconciliationCode(res.data.data);
      } else {
        setModalMsg(res.data.responseMessage);
        setReconciliationCode(res.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenGenericModal(true);
    }
  };

  return (
    <div>
      <p className="tw-font-bold tw-mb-4 tw--mt-4 tw-text-pc-df-mobile tw-text-black">
        Generate Reconciliation Code
      </p>
      {openGenericModal && <GenericModal onButtonClick={resetState} buttonText="Okay" modalTitle={modalMsg} content={`Your reconciliation code is ${reconciliationCode}`} />}
      {loading ? (
        <div className="tw-mx-auto tw-w-max">
          <Spinner />
        </div>
      ) : (<>

        <form onSubmit={handleFormSubmit} className="">
          <button type="submit" className=" tw-rounded-md tw-cursor-pointer focus:tw-outline-none tw-text-white tw-flex pc-font-regular tw-items-center tw-justify-center tw-text-center tw-py-1 tw-mt-4 tw-py-2 tw-w-full tw-bg-pc-secondaryshade2">
            Generate Code
          </button>
        </form>
      </>
      )}
    </div>
  );
};

export default GenerateReconciliationCode;
