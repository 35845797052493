import React from "react";
import useFetch from "../../../../Hooks/useFetch";
import NewAmbassadorCard from "../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import { getLeadingAmbassadors } from "../../../../Services/Dashboard";

const DashCard5 = () => {
	const { isSuccess, isLoading, data } = useFetch(
		"leadingAmbassadors",
		getLeadingAmbassadors
	);

	const hasData = isSuccess && data !== undefined && data.length > 0;
	const isEmpty = isSuccess && data !== undefined && data.length === 0;
	return (
		<>
			<p className="tw-font-bold tw-mb-0 tw-mt-4 tw-text-pc-df-mobile tw-text-black">
				Leading Ambassadors
			</p>
			<NewAmbassadorCard addition="tw-mt-2">
				<div className=" tw-mb-4">
					<img
						src={require("../../../../assets/icons/podium.svg")}
						className="w-4"
						alt=""
					/>
				</div>
				<ul>
					{isEmpty && (
						<div className="tw-text-center tw-text-pc-secondaryshade2 tw-pb-4">
							Leaderboard is Empty
						</div>
					)}
					{isLoading && <Loader />}
					{hasData &&
						data.slice(0, 5).map((each, index) => {
							const { firstname, lastname, numberOfAgents } = each;
							return (
								<li
									key={`performanceList${index}`}
									className={`tw-py-2 tw-px-1 tw-flex tw-items-center tw-justify-between tw-border-b ${
										index !== data.length - 1
											? "tw-border-gray-100"
											: "tw-border-transparent"
									}`}
								>
									<span className="tw-text-pc-sm-mobile tw-text-pc-grey9">
										{`${firstname.toUpperCase()} ${lastname.toUpperCase()}`}
									</span>
									<span
										className={`tw-text-pc-sm-mobile tw-text-black tw-font-bold`}
									>
										{numberOfAgents} {numberOfAgents === 1 ? "Agent" : "Agents"}
									</span>
								</li>
							);
						})}
				</ul>
			</NewAmbassadorCard>
		</>
	);
};

export default DashCard5;

function Loader() {
	return (
		<>
			{["", "", "", ""].map((each, index) => {
				return (
					<li
						key={`performanceList${index}`}
						className={`tw-py-2 tw-flex tw-items-center tw-justify-between tw-border-b ${
							index !== 4 - 1 ? "tw-border-gray-100" : "tw-border-transparent"
						}`}
					>
						<div className="tw-h-5 tw-w-24 tw-rounded tw-animate-pulse tw-bg-gray-200"></div>
						<div className="tw-h-5 tw-w-24 tw-animate-pulse tw-rounded tw-bg-gray-200"></div>
					</li>
				);
			})}
		</>
	);
}
