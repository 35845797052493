import React from "react";
import ScrollToTop from "../../../NewAmbassadorComponents/ScrollRestoration/ScrollRestoration";
import AgentDash1 from "./AgentDash1/AgentDash1";
import AgentDash2 from "./AgentDash2/AgentDash2";

const NewAgentDash = () => {
	return (
		<div>
			<ScrollToTop />
			<AgentDash1 />
			<AgentDash2 />
		</div>
	);
};

export default NewAgentDash;
