import React from "react";

const FailureModal = ({
	content,
	boldText = "",
	buttonText,
	onButtonClick = () => {},
}) => {
	return (
		<section className="tw-z-50 tw-flex tw-items-center tw-justify-center tw-fixed tw-top-0 tw-left-0 tw-bg-opacity-75 tw-h-screen tw-w-screen tw-overflow-hidden tw-p-4 tw-bg-black">
			<div
				className="tw-bg-white tw-shadow tw-rounded tw-flex tw-items-center tw-justify-center tw-p-2 tw-px-4"
				style={{ minWidth: "280px", height: "382px" }}
			>
				<div>
					<img
						src={require("../../assets/img/notice-icon.svg")}
						className="tw-w-20 tw-mx-auto tw-block"
						alt=""
					/>
					<h4
						style={{ color: "#E24C4B" }}
						className="tw-mt-8 tw-font-bold tw-text-center "
					>
						Payment Failed
					</h4>
					<div className="tw-my-8 tw-text-center">
						{content}
						{boldText && <span className="tw-font-bold">{boldText}</span>}
					</div>
					<button
						onClick={onButtonClick}
						style={{ width: "241px" }}
						className={`tw-bg-pc-secondaryshade2 tw-mx-auto tw-mt-10 tw-rounded-md tw-cursor-pointer focus:tw-outline-none tw-text-white tw-flex tw-items-center tw-justify-center tw-text-center tw-py-3`}
					>
						{buttonText}
					</button>
				</div>
			</div>
		</section>
	);
};

export default FailureModal;
