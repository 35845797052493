import React, { useState } from "react";
import { history } from "../../../../App";
import Spinner from "../../../../components/Spinner";
import useFetch from "../../../../Hooks/useFetch";
import { useMutation } from "react-query";
import { getAgentWalletDetails } from "../../../../Services/Dashboard";
import {
	getAmbassadorBankDetails,
	transferToAmbassadorBank,
} from "../../../../Services/WithdrawCommission";
import { formatNumber } from "../../../../utils/formatNumber";
import SuccessModal from "../../../../NewAmbassadorComponents/SuccessModal/SuccessModal";
import FailureModal from "../../../../NewAmbassadorComponents/FailureModal/FailureModal";

const WithdrawPage = () => {
	const [amount, setAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showFailure, setShowFailure] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	console.log(history);

	const { data, isSuccess, isLoading } = useFetch(
		"amb-wallet-details",
		getAmbassadorBankDetails
	);

	const {
		data: waletData,
		isSuccess: waletSuccess,
		isLoading: waletLoading,
	} = useFetch("walet-details", getAgentWalletDetails, {
		onSuccess: (data) => {
			if (data) {
				setMaxAmount(data.walletBalance);
			}
		},
	});

	const closeSuccess = () => {
		setShowSuccess(false);

		const protocol = window.location.protocol;
		const host = window.location.host;
		const url = `${protocol}//${host}/ambassador/dashboard`;

		window.location.href = url;
	};
	const closeFailure = () => {
		setShowFailure(false);
		setErrorMessage("");
	};

	const postFunction = async (body: any) => {
		const response = await transferToAmbassadorBank(body);
		return response;
	};

	const { isLoading: mutationLoading, mutate } = useMutation(postFunction, {
		onSuccess: (data) => {
			console.log("success", data);
			if (data.data && data.data.responseCode === "00") {
				setShowSuccess(true);
			}
		},
		onError: (data) => {
			console.log("error", data.response);
			if (data.response === undefined) {
				setErrorMessage(
					"An error occured. Kindly check your network and try again."
				);
				setShowFailure(true);
			} else {
				setErrorMessage(data.response.data.responseMessage);
				setShowFailure(true);
			}
		},
	});

	const hasData = isSuccess && waletSuccess && data && waletData;

	// const isDisabled = false;
	const isDisabled = !amount || amount <= 0 || amount > maxAmount;

	const loading = isLoading || waletLoading;

	const handleInputChange = (e) => {
		setAmount(e.target.value);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		const date = new Date();
		const transactionId =
			"TT" +
			date.getFullYear() +
			("0" + (date.getMonth() + 1)) +
			("0" + date.getDate()) +
			("0" + date.getHours()) +
			("0" + date.getMinutes()) +
			("0" + date.getSeconds()) +
			"AMB";

		const _amount = parseFloat(amount);
		mutate({ amount: _amount, transactionId });
	};

	return (
		<div>
			<div
				onClick={() => {
					history.goBack();
				}}
				className="tw-flex tw-cursor-pointer tw-items-center"
			>
				<img
					src={require("../../../../assets/icons/back-arrow.svg")}
					className="tw-w-5 tw-mr-6"
					alt=""
				/>

				<span className="tw-text-black tw-font-bold">Withdraw Commission</span>
			</div>

			{showSuccess && (
				<SuccessModal
					content={`Your withdrawal of ₦${parseFloat(
						amount
					).toLocaleString()} has been successfully processed.`}
					onButtonClick={closeSuccess}
					buttonText="Okay"
				/>
			)}
			{showFailure && (
				<FailureModal
					content={errorMessage}
					onButtonClick={closeFailure}
					buttonText="Retry"
				/>
			)}

			{loading && (
				<div className="tw-flex tw-justify-center tw-h-40 tw-items-center">
					<Spinner />
				</div>
			)}

			{hasData && (
				<form
					onSubmit={handleFormSubmit}
					style={{ height: "70vh" }}
					className="tw-flex tw-flex-col tw-pt-5 tw-justify-between"
				>
					<div>
						<span className="tw-block tw-font-semibold tw-text-black">
							Withdraw commission to
						</span>

						<div className="tw-mt-4">
							<div className="tw-border tw-items-center tw-bg-white tw-p-3 tw-border-dashed tw-py-5 tw-rounded-lg tw-border-pc-grey5">
								<div className="tw-flex tw-justify-between tw-mb-2 tw-items-center">
									<p className="tw-mb-0 tw-text-sm tw-text-pc-grey9">
										Bank Name
									</p>
									<p className="tw-mb-0 tw-font-semibold tw-text-black">
										{data.bank.name}
									</p>
								</div>
								<div className="tw-flex tw-justify-between tw-mb-2 tw-items-center">
									<p className="tw-mb-0 tw-text-sm tw-text-pc-grey9">
										Account Number
									</p>
									<p className="tw-mb-0 tw-font-semibold tw-text-black">
										{data.accountNumber}
									</p>
								</div>
								<div className="tw-flex tw-justify-between tw-items-center">
									<p className="tw-mb-0 tw-text-sm tw-text-pc-grey9">
										Account Name
									</p>
									<p className="tw-mb-0 tw-font-semibold tw-text-black">
										{data.accountName}
									</p>
								</div>
							</div>
							<span className="tw-block tw-text-xs tw-text-red-500 tw-p-3 tw-bg-red-100 tw-rounded tw-mt-2">
								Please note! ₦20 will be deducted as convienience fee
							</span>
						</div>
						<div className="tw-mt-8">
							<label
								htmlFor="amount"
								className="tw-block tw-text-sm tw-font-bold tw-mb-4"
							>
								Withdrawal Amount
							</label>
							{isEditing ? (
								<div className="tw-h-12 tw-pl-2 tw-flex tw-items-center tw-bg-white tw-rounded-lg">
									<span>₦</span>
									<input
										required
										value={amount}
										name="amount"
										onChange={handleInputChange}
										onBlur={() => {
											setIsEditing(false);
										}}
										type="number"
										id="amount"
										className="tw-pr-1 tw-pl-1 tw-block tw-flex-1 tw-bg-transparent focus:tw-outline-none"
									/>
								</div>
							) : (
								<div className="tw-h-12 tw-pl-2 tw-flex tw-items-center tw-bg-white tw-rounded-lg">
									<span>₦</span>
									<input
										// required
										value={amount ? parseFloat(amount).toLocaleString() : ""}
										name="amount1"
										readonly
										onFocus={() => {
											setIsEditing(true);
										}}
										type="text"
										id="amount1"
										className="tw-pr-1 tw-pl-1 tw-block tw-flex-1 tw-bg-transparent focus:tw-outline-none"
									/>
								</div>
							)}

							<span className="tw-block tw-mt-2 tw-text-pc-fn-mobile tw-text-pc-grey9">
								Maximum Amount Available : ₦{formatNumber(maxAmount)}
							</span>
						</div>
					</div>

					<div className="tw-pb-8">
						{mutationLoading && (
							<div className="tw-flex tw-justify-center">
								<Spinner height="20" width="20" />
							</div>
						)}
						{!mutationLoading && (
							<button
								type="submit"
								disabled={isDisabled}
								className={`tw-mt-10 tw-w-full tw-rounded-md ${
									isDisabled
										? "tw-cursor-not-allowed tw-bg-blue-200"
										: "tw-cursor-pointer tw-bg-pc-secondaryshade2"
								} focus:tw-outline-none tw-text-white tw-flex tw-items-center tw-justify-center tw-text-center tw-py-3`}
							>
								Withdraw Commission
							</button>
						)}
					</div>
				</form>
			)}
		</div>
	);
};

export default WithdrawPage;
