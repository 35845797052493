import React, { useState, useEffect, useRef } from "react";
import useFetch from "../../../../Hooks/useFetch";

import AmbassadorArrow from "../../../../NewAmbassadorComponents/Arrow/AmbassadorArrow";
import NewAmbassadorCard from "../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import AmbassadorDivider from "../../../../NewAmbassadorComponents/Divider/AmbassadorDivider";
import DurationDropdown from "../../../../NewAmbassadorComponents/DurationDropdown/DurationDropdown";
import { getAgentTransactionBreakdown } from "../../../../Services/Dashboard";
import { formatNumber } from "../../../../utils/formatNumber";

const dropDownOptions = [
	{ label: "Today", value: "dailyTransactionBreakdown" },
	{ label: "1 Week", value: "weeklyTransactionBreakdown" },
	{ label: "1 Month", value: "monthlyTransactionBreakdown" },
];

const match = {
	Today: "Daily",
	"1 Week": "Weekly",
	"1 Month": "Monthly",
};

const TransactionDash1 = () => {
	const startRef = useRef(false);
	const [result, setResult] = useState({});
	const [filter, setFilter] = useState("dailyTransactionBreakdown");
	const [label, setLabel] = useState("Today");
	const [currentData, setCurrentData] = useState({});

	const { isLoading, isSuccess, data } = useFetch(
		"transaction-details2",
		getAgentTransactionBreakdown,
		{
			// onSuccess: (data) => {
			// 	if (data) {
			// 		setResult((prev) => data);
			// 		setCurrentData(data[filter]);
			// 	}
			// },
			keepPreviousData: true,
		}
	);
	// console.log(currentData, isLoading);
	const hasData = isSuccess;
	// console.log("isSuccess", isSuccess);
	// console.log("second", Object.keys(currentData).length > 0);

	// useEffect(() => {
	// 	if (startRef.current && Object.keys(result).length > 0) {
	// 		setCurrentData(result[filter]);
	// 	}
	// 	startRef.current = true;
	// }, [filter]);

	return (
		<>
			<p className="tw-font-bold tw-mb-0 tw-text-pc-df-mobile tw-text-black">
				Agents Transaction Summary
			</p>
			{isLoading && <Loader />}
			{hasData && (
				<NewAmbassadorCard addition="tw-mt-2">
					<div className="tw-flex tw-justify-between tw-items-center tw-mb-8">
						<img
							src={require("../../../../assets/icons/card3-icon.svg")}
							className="w-4"
							alt=""
						/>
						<DurationDropdown
							options={dropDownOptions}
							setFunction={setFilter}
							value={filter}
							label={label}
							setLabel={setLabel}
						/>
					</div>
					<span className="tw-block tw-text-pc-df-mobile tw-text-pc-grey9 tw-mt-2">
						Transaction Value
					</span>
					<div className="tw-mt-pc-8 tw-flex tw-items-center">
						<h6 className="tw-font-bold tw-mb-0 tw-mr-3 tw-text-pc-h4-desktop tw-text-black">
							₦{formatNumber(data[filter][`total${match[label]}Value`])}
						</h6>
						{/* <AmbassadorArrow type="increaseGraph" /> */}
					</div>
					<AmbassadorDivider addition="tw-my-3" />
					<span className="tw-block tw-text-pc-df-mobile tw-text-pc-grey9">
						Transaction Volume
					</span>
					<div className="tw-mt-pc-8 tw-flex tw-items-center">
						<h6 className="tw-font-bold tw-mb-0 tw-mr-3 tw-text-pc-h4-desktop tw-text-black">
							{data[filter][`total${match[label]}Volume`]}
						</h6>
						{/* <AmbassadorArrow type="decreaseGraph" /> */}
					</div>

					<div className="tw-mb-1 tw-mt-4 tw-flex tw-justify-between tw-items-center">
						<div>
							<span className="tw-block tw-text-pc-sm-mobile tw-text-green-500">
								Successful
							</span>
							<div className="">
								<p className="tw-font-bold tw-mb-0 tw-text-pc-df-mobile tw-text-black">
									{data[filter][`successful${match[label]}Volume`]}
								</p>
							</div>
						</div>
						{/* <div>
						<span className="tw-block tw-text-pc-sm-mobile tw-text-yellow-500">
							Pending
						</span>
						<div className="">
							<p className="tw-font-bold tw-mb-0 tw-text-pc-df-mobile tw-text-black">
								287
							</p>
						</div>
					</div> */}
						<div>
							<span className="tw-block tw-text-pc-sm-mobile tw-text-red-500">
								Failed
							</span>
							<div className="">
								<p className="tw-font-bold tw-mb-0 tw-text-pc-df-mobile tw-text-black">
									{data[filter][`failed${match[label]}Volume`]}
								</p>
							</div>
						</div>
					</div>
				</NewAmbassadorCard>
			)}
		</>
	);
};

export default TransactionDash1;

function Loader() {
	return (
		<NewAmbassadorCard addition="tw-mt-2">
			<div className="tw-flex tw-justify-between tw-items-center tw-mb-8">
				<span className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-200 tw-animate-pulse"></span>
				<span className="pulse-loader tw-w-16"></span>
			</div>
			<span className="tw-block pulse-loader tw-w-28 tw-text-pc-df-mobile tw-text-pc-grey9 tw-mt-2"></span>
			<div className="tw-mt-pc-8 tw-flex tw-items-center">
				<span className="tw-font-bold pulse-loader tw-w-36 tw-mb-0 tw-mr-3 tw-text-pc-h4-desktop tw-text-pc-secondaryshade2"></span>
				{/* <AmbassadorArrow type="increaseGraph" /> */}
			</div>
			<AmbassadorDivider addition="tw-my-5" />
			<span className="tw-block pulse-loader tw-w-36 tw-text-pc-df-mobile tw-text-pc-grey9"></span>
			<div className="tw-mt-pc-8 tw-flex tw-items-center">
				<span className="tw-font-bold pulse-loader tw-w-28 tw-mb-0 tw-mr-3 tw-text-pc-h4-desktop tw-text-pc-secondaryshade2"></span>
				{/* <AmbassadorArrow type="decreaseGraph" /> */}
			</div>

			<div className="tw-mb-1 tw-mt-7 tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block pulse-loader tw-w-24 tw-text-pc-sm-mobile tw-text-green-500"></span>
					<div className="mt-2">
						<p className="tw-font-bold pulse-loader tw-w-16 tw-mb-0 tw-text-pc-df-mobile tw-text-black"></p>
					</div>
				</div>
				<div>
					<span className="tw-block pulse-loader tw-w-24 tw-text-pc-sm-mobile tw-text-yellow-500"></span>
					<div className="mt-2">
						<p className="tw-font-bold pulse-loader tw-w-16 tw-mb-0 tw-text-pc-df-mobile tw-text-black"></p>
					</div>
				</div>
				<div>
					<span className="tw-block tw-text-pc-sm-mobile pulse-loader tw-w-24 tw-text-red-500"></span>
					<div className="mt-2">
						<p className="tw-font-bold tw-mb-0 tw-text-pc-df-mobile pulse-loader tw-w-16 tw-text-black"></p>
					</div>
				</div>
			</div>
		</NewAmbassadorCard>
	);
}
