exports = module.exports = require("../../../node_modules/react-scripts/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pagination_activeClass__1W9pn {\n  color: #ffffff !important; }\n", ""]);

// exports
exports.locals = {
	"activeClass": "pagination_activeClass__1W9pn"
};