import React, { useState } from "react";
import useFetch from "../../../../Hooks/useFetch";
import useFetchWithParams from "../../../../Hooks/useFetchWithParams";
import NewAmbassadorCard from "../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import DurationDropdown from "../../../../NewAmbassadorComponents/DurationDropdown/DurationDropdown";
import { getPerformingAgents } from "../../../../Services/Dashboard";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import NewAmbassadorCard from "../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
// import DurationDropdown from "../../../../NewAmbassadorComponents/DurationDropdown/DurationDropdown";
import AmbassadorPerformanceList from "./PerformanceList/AmbassadorPerformanceList";

const dropDownOptions = [
	// { label: "Overall", value: "daily" },
	// { label: "Daily", value: "" },
	{ label: "Overall", value: "" },
	{ label: "Daily", value: "daily" },
];

const DashCard4 = () => {
	const [value, setValue] = useState("daily");
	const [label, setLabel] = useState("Daily");
	// const [label, setLabel] = useState("Overall");
	const { data, isLoading, isError, isSuccess } = useFetchWithParams(
		["top-performing", { value }],
		getPerformingAgents,
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		}
	);

	const _handleDurationValue = (val) => {
		setValue((prev) => val);
	};

	// isSuccess && console.log(data);

	// const empty = isSuccess && data.leastPerformingAgents.length === 0;
	// const hasData = isSuccess && data.leastPerformingAgents.length > 0;
	// const hasData2 = isSuccess && data.topPerformingAgents.length > 0;
	// const result = useFetch("performing", getPerformingAgents, value);

	// console.log(result);
	const [tab, setTab] = useState("top");
	const setTabTop = () => {
		setTab((prev) => "top");
	};
	const setTabLeast = () => {
		setTab((prev) => "least");
	};

	return (
		<section>
			<p className="tw-font-bold tw-mb-0 tw-mt-8 tw-text-pc-df-mobile tw-text-black">
				Agents Performance
			</p>
			<div className="tw-mt-4 tw-flex tw-justify-between tw-px-2 tw-items-center">
				<div onClick={setTabTop}>
					<p
						className={`${
							tab === "top" ? "tw-text-pc-secondaryshade2" : ""
						} tw-text-pc-sm-mobile tw-mb-1`}
					>
						Top Performers
					</p>
					<div className="tw-flex tw-mt-1 tw-justify-center">
						<span
							className={`tw-w-2 tw-h-2 tw-inline-block tw-rounded-full  ${
								tab === "top"
									? "tw-bg-pc-secondaryshade2"
									: "tw-bg-pc-transparent"
							}`}
						></span>
					</div>
				</div>
				<div onClick={setTabLeast}>
					<p
						className={`${
							tab === "least" ? "tw-text-pc-secondaryshade2" : ""
						} tw-text-pc-sm-mobile tw-mb-1`}
					>
						Least Performers
					</p>
					<div className="tw-flex tw-mt-1 tw-justify-center">
						<span
							className={`tw-w-2 tw-h-2 tw-inline-block tw-rounded-full  ${
								tab === "least"
									? "tw-bg-pc-secondaryshade2"
									: "tw-bg-pc-transparent"
							}`}
						></span>
					</div>
				</div>
			</div>
			<div className="tw-mt-4 tw-pb-6">
				<NewAmbassadorCard addition="tw-mt-2">
					<div className="tw-flex tw-justify-between tw-items-center tw-mb-8">
						<img
							src={require("../../../../assets/icons/card3-icon.svg")}
							className="w-4"
							alt=""
						/>
						<DurationDropdown
							setFunction={_handleDurationValue}
							setLabel={setLabel}
							value={value}
							options={dropDownOptions}
							label={label}
						/>
					</div>

					{isLoading && <Loader />}
					{/* <Loader /> */}

					{/* {empty && (
						<div className="tw-text-center tw-text-pc-secondaryshade2 tw-pb-4">
							Agent List is Empty
						</div>
					)} */}

					{isError && (
						<div className="tw-text-center tw-text-pc-secondaryshade2 tw-pb-4">
							<p>Error fetching agent List</p>
						</div>
					)}
					{isSuccess && (
						<div>
							{tab === "top" && (
								<AmbassadorPerformanceList
									list={data.data.topPerformingAgents}
									id="top-performmers"
								/>
							)}
							{tab === "least" && (
								<AmbassadorPerformanceList
									type="least"
									list={data.data.leastPerformingAgents}
									id="least-performers"
								/>
							)}
						</div>
					)}
				</NewAmbassadorCard>
			</div>
		</section>
	);
};

export default DashCard4;

function Loader() {
	return (
		<>
			{["", "", "", ""].map((each, index) => {
				return (
					<li
						key={`performanceList${index}`}
						className={`tw-py-2 tw-flex tw-items-center tw-justify-between tw-border-b ${
							index !== 4 - 1 ? "tw-border-gray-100" : "tw-border-transparent"
						}`}
					>
						<div className="tw-h-5 tw-w-24 tw-rounded tw-animate-pulse tw-bg-gray-200"></div>
						<div className="tw-h-5 tw-w-24 tw-animate-pulse tw-rounded tw-bg-gray-200"></div>
					</li>
				);
			})}
		</>
	);
}
