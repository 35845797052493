import React, { useState, useEffect, useRef } from "react";
// import { history } from "../../App";
import { connect } from "react-redux";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as MyAgentsIcon } from "../../assets/icons/myagents-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";
import { ReactComponent as TransactionsIcon } from "../../assets/icons/transaction-icon.svg";
import { ReactComponent as RegisterIcon } from "../../assets/icons/register-icon.svg";
import { ReactComponent as ActivationIcon } from "../../assets/icons/activation-code-icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-icon.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/help-icon.svg";
import SidebarItems from "./SidebarItems/SidebarItems";

const sidebarData = [
  {
    label: "Dashboard",
    to: "/ambassador/dashboard",
    component: DashboardIcon,
    isActive: true,
    addMargin: true,
  },
  {
    label: "My Agents",
    to: "/ambassador/agents",
    component: MyAgentsIcon,
    isActive: false,
    addMargin: true,
  },
  {
    label: "Transactions",
    to: "/ambassador/transactions",
    component: TransactionsIcon,
    isActive: false,
    addMargin: true,
  },
  {
    label: "Generate Reconciliation Code",
    to: "/ambassador/generate-reconciliation-code",
    component: ActivationIcon,
    isActive: false,
    addMargin: true,
  },
  // {
  // 	label: "Register Agent",
  // 	to: "#",
  // 	component: RegisterIcon,
  // 	isActive: false,
  // 	addMargin: true,
  // },
  // {
  // 	label: "Get Activation Code",
  // 	to: "/ambassador/test7",
  // 	component: ActivationIcon,
  // 	isActive: false,
  // 	addMargin: true,
  // },
  // {
  // 	label: "Settings",
  // 	to: "/ambassador/test8",
  // 	component: SettingsIcon,
  // 	isActive: false,
  // 	addMargin: false,
  // },
];

const NewSidebar = ({ data, action, ambassadorName }) => {
  const locationRef = useRef(false);

  const _handleLogout = () => {
    // console.log("timeout");
    localStorage.removeItem("amb_pc");
    localStorage.removeItem("user_pc");
    window.location.href = "/login";
  };

  const [currentPath, setCurrentPath] = useState(() => {
    return window.location.pathname;
  });
  // console.log(currentPath);

  useEffect(() => {
    if (locationRef.current) {
      setCurrentPath(window.location.pathname);
    }
    locationRef.current = true;
  });

  return (
    <nav
      className={`${data ? "tw--translate-x-0" : "tw--translate-x-full"
        } tw-transform tw-transition-transform tw-bg-white tw-z-50 tw-h-screen tw-w-screen tw-fixed tw-top-0 tw-bottom-0 tw-right-0 tw-left-0 tw-py-8`}
    >
      <div className="global-container-amb tw-relative tw-h-full">
        <img
          src={require("../../assets/icons/close.svg")}
          className="tw-w-3 tw-text-pc-grey9 tw-absolute"
          onClick={() => {
            action();
          }}
          style={{ right: "4%", top: "-22px" }}
          alt=""
        />
        <div
          style={{ backgroundColor: "#F4F5F6" }}
          className="tw-p-4 tw-rounded-md"
        >
          <span className="tw-text-pc-secondaryshade2">Hello!</span>
          <p className="tw-mt-pc-8 tw-mb-0 tw-font-bold tw-text-pc-secondaryshade2">
            {ambassadorName}
          </p>
          {/* <div className="tw-mt-pc-16 tw-grid tw-grid-cols-2 tw-gap-2">
						<div className="tw-w-full tw-rounded-3xl tw-bg-pc-secondaryshade2 tw-flex tw-items-center tw-justify-center tw-py-1">
							<span className="tw-text-white tw-text-pc-sm-mobile tw-mr-1">
								Champion
							</span>
							<img
								src={require("../../assets/icons/medal.svg")}
								className="tw-w-5 tw-ml-1"
								alt=""
							/>
						</div>
						<div className="tw-w-full tw-rounded-3xl tw-bg-pc-deepbblue tw-flex tw-items-center tw-justify-center tw-py-1">
							<span className="tw-text-white tw-text-pc-sm-mobile tw-mr-1">
								35 Agents
							</span>
							<img
								src={require("../../assets/icons/usersagent-icon.svg")}
								className="tw-w-5 tw-ml-1"
								alt=""
							/>
						</div>
					</div> */}
        </div>
        <div className="sidebar-content-height tw-flex tw-flex-col tw-pt-8">
          <div className="tw-flex-1">
            {sidebarData.map((each, index) => {
              return (
                <SidebarItems
                  key={`sidebarItem${index}`}
                  onClick={() => {
                    action();
                  }}
                  component={each.component}
                  isActive={currentPath.includes(each.to)}
                  addMargin={each.addMargin}
                  to={each.to}
                  label={each.label}
                  index={index + 1}
                />
              );
            })}
          </div>
          <div className="tw-h-2/5 tw-border-t tw-border-gray-200 tw-pt-2">
            <SidebarItems
              component={LogoutIcon}
              onClick={_handleLogout}
              isActive={false}
              addMargin={false}
              to={"#"}
              label={"Logout"}
              index={6}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    ambassadorName: state.user.ambUser.accountName,
  };
};

export default connect(mapStateToProps, null)(NewSidebar);
