import React from "react";

const NewAmbassadorCard = ({ children, addition = "", style = {} }) => {
	return (
		<div
			style={{
				borderRadius: "10px",
				boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.04)",
				...style,
			}}
			className={`tw-bg-white tw-p-3 ${addition}`}
		>
			{children}
		</div>
	);
};

export default NewAmbassadorCard;
