import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { setSignedInUser, setAmbassadorData } from "../redux/actions/userActions";

const AmbassadorGuard = ({ children, isAuth, setAmbassadorData, ...rest }) => {
	useEffect(() => {
		if (localStorage.getItem("amb_pc")) {
			const user = JSON.parse(localStorage.getItem("amb_pc"));
			setAmbassadorData(user);
		}
	}, []);
	// console.log(rest, isAuth);
	return (
		<Route
			{...rest}
			render={(props) => {
				if (localStorage.getItem("amb_pc")) {
					// const user = JSON.parse(localStorage.getItem("amb_pc"));
					// setAmbassadorData(user);
					return children;
				} else {
					return <Redirect to="/" />;
				}
			}}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		isAuth: state.user.isAuth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAmbassadorData: (data) => dispatch(setAmbassadorData(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AmbassadorGuard);
