const initialState = {
	accounts: [],
	user: {},
	ambUser: {},
	dataProfile: null,
	isAuth: false,
	agents: [],
};

export default function user(state = initialState, action) {
	switch (action.type) {
		case "LOAD_ACCOUNTS":
			return {
				...state,
				accounts: action.payload.accounts,
			};
			break;

		case "LOAD_AGENTS":
			return {
				...state,
				agents: action.payload.agents,
			};
			break;

		case "SET_SIGNED_IN_USER":
			let user = Object.assign({}, action.payload.userDetails);

			return {
				...state,
				user: user,
				isAuth: true,
			};
			break;
		case "LOGOUT_USER":
			return {
				...state,
				user: {},
				isAuth: false,
			};
			break;

		case "SET_AMBASSADOR_DATA":
			let ambuser = Object.assign({}, action.payload.data);

			return {
				...state,
				ambUser: ambuser,
				isAuth: true,
			};
			break;

		case "PROFILE_DATA":
			return {
				...state,
				dataProfile: action.payload.profileData,
			};
			break;

		default:
			return state;
	}
	return state;
}
