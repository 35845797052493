import React, { useState } from "react";
import { history } from "../../../App";
import { Link } from "react-router-dom";


import CommissionCard from "../../../NewAmbassadorComponents/CommissionCard/CommissionCard";
import NewPagination from "../../../NewAmbassadorComponents/NewPagination/NewPagination";
import CommissionLoader from "../../../NewAmbassadorComponents/CommissionCard/CommissionLoader";
import useFetchWithParams from "../../../Hooks/useFetchWithParams";
import DurationDropdown from "../../../NewAmbassadorComponents/DurationDropdown/DurationDropdown";
import { getCommissionHistory } from "../../../Services/Commission-History";
import dayjs from "dayjs";
import DatePicker from "../../../NewAmbassadorComponents/DatePicker/DatePicker";
import NoticeModal from "./NoticeModal";

const dummy = [
	{ month: "January", amount: 5000, status: "collected" },
	{ month: "February", amount: 4000, status: "not-collected" },
	{ month: "March", amount: 6000, status: "collected" },
	{ month: "April", amount: 7000, status: "not-collected" },
];

const dropDownOptions = [
	{ label: "All Time", value: "all" },
	{ label: "This Year", value: "this-year" },
	{ label: "Last Year", value: "last-year" },
	{ label: "2 Years ago", value: "2-years-ago" },
	{ label: "Custom", value: "Custom" },
];

const CommissionHistory = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [totalPages, setTotalPages] = useState(1);
	const [value, setValue] = useState("all");
	const [dropVal, setDropVal] = useState("all");
	const [label, setLabel] = useState("All Time");
	const [custom, setCustom] = useState(false);
	const [startDate, setStartDate] = useState(
		dayjs().subtract(5, "month").toDate()
	);
	const [endDate, setEndDate] = useState(dayjs().toDate());

	const _handleDropdownValue = (value) => {
		setCurrentPage(1);
		if (value !== "Custom") {
			if (custom) {
				setCustom(false);
			}

			setValue((prev) => value);
			setDropVal((prev) => value);
		} else {
			setCustom(true);
			setDropVal((prev) => value);
		}
	};

	const _handleApplyClick = () => {
		const customFilter = `?startYear=${dayjs(startDate).year()}&endMonth=${
			dayjs(endDate).month() + 1
		}&endYear=${dayjs(endDate).year()}&length=15&startMonth=${
			dayjs(startDate).month() + 1
		}`;

		setValue(customFilter);
	};

	const { data, isLoading, isSuccess } = useFetchWithParams(
		["commission-history", { page: currentPage, filter: value }],
		getCommissionHistory,
		{
			onSuccess: (data) => {
				if (data !== undefined) {
					setTotalPages(data.totalPages);
				}
			},
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		}
	);

	const openNoticeModal = () => {
		setIsModalOpen(true);
	};

	const hasData = isSuccess && data !== undefined && data.data.length > 0;
	const isEmpty = isSuccess && data !== undefined && data.data.length === 0;
	const result = hasData
		? data.data.map((each, index) => {
				return {
					month: each.month,
					amount: each.amountAccrued,
					status: each.collected ? "collected" : "not-collected",
					year: each.year,
				};
		  })
		: [];

	const showPagination = Math.ceil(totalPages / 15) > 1;

	const changePage = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
		// console.log(selected);
	};
	return (
		<div>
			{isModalOpen && (
				<NoticeModal
					close={() => {
						setIsModalOpen(false);
					}}
				/>
			)}
			<div
				onClick={() => {
					history.goBack();
				}}
				className="tw-flex tw-mb-8 tw-cursor-pointer tw-items-center"
			>
				<img
					src={require("../../../assets/icons/back-arrow.svg")}
					className="tw-w-5 tw-mr-6"
					alt=""
				/>

				<span className="tw-text-black pc-font-bold tw-font-bold">
					Commission History
				</span>
			</div>
			<div className="tw-mb-8 mt-3 tw-flex tw-items-center tw-justify-between">
				<p className="tw-mb-0">Showing Commission for:</p>
				<DurationDropdown
					setFunction={_handleDropdownValue}
					setLabel={setLabel}
					value={dropVal}
					options={dropDownOptions}
					label={label}
				/>
			</div>

			{custom && (
				<div className="tw-mt-6 tw-mb-8 tw-flex">
					<div>
						<DatePicker date={startDate} setDate={setStartDate} text="From" />
					</div>
					<div className="tw-ml-2">
						<DatePicker date={endDate} setDate={setEndDate} text="To" />
					</div>
					<div className="tw-flex-1 tw-self-end tw-ml-2">
						<button
							onClick={_handleApplyClick}
							className="tw-inline-block tw-w-full tw-bg-pc-secondaryshade1 tw-text-white tw-rounded-md tw-py-1 text-center focus:tw-outline-none tw-border-transparent"
						>
							Apply
						</button>
					</div>
				</div>
			)}

			{isEmpty && (
				<div className="tw-text-center tw-text-pc-secondaryshade2 tw-pb-4">
					Empty Record
				</div>
			)}
			{isLoading && <CommissionLoader />}
			{hasData && (
				<>
					<div style={{ minHeight: "55vh" }}>
						{result.map((each, index) => {
							const borderBottom = index !== result.length - 1 ? true : false;
							return (
								<CommissionCard
									data={each}
									openNoticeModal={openNoticeModal}
									index={index}
									borderBottom={borderBottom}
								/>
							);
						})}
					</div>
				</>
			)}

			{showPagination && (
				<div className="tw-mt-12">
					<NewPagination
						totalPages={totalPages > 1 ? Math.ceil(totalPages / 15) : 1}
						currentPage={currentPage}
						changePage={changePage}
					/>
				</div>
			)}
		</div>
	);
};

export default CommissionHistory;
