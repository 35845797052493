import React, { useState } from "react";
import NewFooter from "../../../NewAmbassadorComponents/Footer/NewFooter";
import NewNavbar from "../../../NewAmbassadorComponents/Navbar/NewNavbar";
import SessionTimer from "../../../NewAmbassadorComponents/SessionTimer/SessionTimer";

import NewSidebar from "../../../NewAmbassadorComponents/Sidebar/NewSidebar";

const NewAmbassadorLayout = ({ children }) => {
	const [isNavOpen, setIsNavOpen] = useState(false);

	const toggleNav = () => {
		setIsNavOpen((prev) => {
			return !prev;
		});
	};

	return (
		<div style={{ backgroundColor: "#F4F5F6" }}>
			<SessionTimer />
			<NewNavbar action={toggleNav} />
			<NewSidebar data={isNavOpen} action={toggleNav} />
			<main
				style={{ backgroundColor: "#F4F5F6" }}
				className="tw-pt-8 tw-mt-16 main-height-amb"
			>
				<div className="global-container-amb h-full">{children}</div>
			</main>
			<NewFooter />
		</div>
	);
};

export default NewAmbassadorLayout;
