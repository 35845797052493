import React from "react";
import GlobalButton from "../../../NewAmbassadorComponents/Buttons/GlobalButton";

const NoticeModal = ({ close }) => {
	return (
		<div
			style={{ zIndex: 1007 }}
			className="tw-h-screen tw-fixed tw-top-0 tw-bottom-0 tw-right-0 tw-left-0 tw-w-screen tw-p-6 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-70"
		>
			<div
				style={{ width: "343px" }}
				className="tw-p-6 tw-rounded-lg tw-bg-white tw-shadow-2xl"
			>
				<img
					src={require("../../../assets/img/notice-icon.svg")}
					className="tw-h-20 tw-mx-auto tw-block"
					alt=""
				/>
				<p className="tw-my-4 tw-font-bold tw-text-xl tw-text-center">Notice</p>

				<p className="tw-text-pc-grey7 tw-text-center">
					You can only withdraw your commission on the 4th of the next month
				</p>
				<div className="tw-mt-8 tw-flex tw-justify-center">
					<GlobalButton
						to="#"
						action={close}
						label="Okay"
						type="button"
						addition="tw-px-8 tw-h-12 tw-w-full tw-bg-pc-secondaryshade2"
					/>
				</div>
			</div>
		</div>
	);
};

export default NoticeModal;
