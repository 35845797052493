import React from "react";

const NewFooter = () => {
	return (
		<footer
			style={{ backgroundColor: "#F4F5F6" }}
			className="tw-h-16 tw-flex tw-text-pc-sm-mobile tw-justify-center tw-items-center"
		>
			Powered by E-Settlement Limited 2021
		</footer>
	);
};

export default NewFooter;
