import React from "react";
import NewAmbassadorCard from "../Cards/NewAmbassadorCard";
import { Link } from "react-router-dom";
import AmbassadorDivider from "../Divider/AmbassadorDivider";

const capitalizeFirstLetter = (val) => {
	const firstLetter = val.slice(0, 1).toUpperCase();
	return `${firstLetter}${val.slice(1).toLowerCase()}`;
};

const colorPallete = {
	SUBSCRIBER: "#D21C1C",
	PARTNER: "#18D7A9",
	PREMIUM: "#9B51E0",
	CHAMPION: "#000000",
	BASIC: "#4285F4",
	ROOKIE: "#FFC200",
	INACTIVE: "#d1d5db",
};

const AgentCard = ({ color, marginBottom = "tw-mb-5", data }) => {
	// console.log(data);
	return (
		<NewAmbassadorCard addition={marginBottom}>
			<div className="tw-flex tw-mt-2 tw-mb-4 tw-justify-end">
				<Link
					className="tw-flex tw-items-center tw-justify-center tw-px-2 tw-bg-pc-lightblue tw-py-1 tw-border tw-rounded-md hover:tw-no-underline tw-border-pc-secondaryshade2"
					to={`/ambassador/agent-transaction-history/${data.userId}`}
				>
					<span className="tw-text-pc-secondaryshade2 tw-text-pc-sm-mobile  tw-mr-1">
						View Transactions
					</span>
					<img
						src={require("../../assets/icons/chevron-right.svg")}
						className="tw-w-4 tw-ml-1"
						alt=""
					/>
				</Link>
				{/* <span
					style={{ background: colorPallete[data.agentGroup] }}
					className={`tw-h-5 tw-w-5 tw-rounded-full`}
				></span> */}
			</div>
			<div>
				<div className="tw-flex tw-justify-between tw-items-center">
					<span className="tw-text-pc-grey9 tw-text-pc-df-desktop">
						{data.businessName}
					</span>
					<p className=" tw-text-black tw-font-bold tw-mb-0 tw-text-pc-df-desktop">
						{data.memberId}
					</p>

					{/* <Link
						className="tw-flex tw-items-center tw-justify-center tw-px-2 tw-bg-pc-lightblue tw-py-1 tw-border tw-rounded-md hover:tw-no-underline tw-border-pc-secondaryshade2"
						to={`/ambassador/agent-transaction-history/${data.userId}`}
					>
						<span className="tw-text-pc-secondaryshade2 tw-text-pc-sm-mobile  tw-mr-1">
							View Transactions
						</span>
						<img
							src={require("../../assets/icons/chevron-right.svg")}
							className="tw-w-4 tw-ml-1"
							alt=""
						/>
					</Link> */}
				</div>
			</div>
			<AmbassadorDivider addition="tw-my-4" />
			<div className="tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
						Phone Number
					</span>
					<p className="tw-font-bold tw-text-pc-sm-mobile tw-mt-pc-6 tw-text-black">
						{data.phoneNumber}
					</p>
				</div>
				<div>
					<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">Status</span>
					<p className="tw-font-bold tw-text-pc-sm-mobile tw-mt-pc-6 tw-text-black">
						{capitalizeFirstLetter(data.agentGroup)}
					</p>
				</div>
				{/* <div className="tw-text-right">
					<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
						Transactions Today
					</span>
					<p className="tw-font-bold tw-text-pc-df-mobile tw-mt-pc-6 tw-text-black">
						₦42,650.00
					</p>
				</div> */}
			</div>
		</NewAmbassadorCard>
	);
};

export default AgentCard;
