import React from "react";
import IncreaseArrow from "../../assets/icons/increase-arrow.svg";
import DecreaseArrow from "../../assets/icons/decrease-arrow.svg";
import IncreaseGraph from "../../assets/icons/increase-graph.svg";
import DecreaseGraph from "../../assets/icons/decrease-graph.svg";

const AmbassadorArrow = ({ type = "increaseArrow", width = "tw-w-4" }) => {
	switch (type) {
		case "decreaseArrow":
			return <img src={DecreaseArrow} alt="arrow-icon" className={`${width}`} />;
		case "increaseGraph":
			return <img src={IncreaseGraph} alt="arrow-icon" className={`${width}`} />;
		case "decreaseGraph":
			return <img src={DecreaseGraph} alt="arrow-icon" className={`${width}`} />;
		default:
			return <img src={IncreaseArrow} alt="arrow-icon" className={`${width}`} />;
	}
};

export default AmbassadorArrow;
