import React from "react";
import SelectInput from "../../SelectInput.js/SelectInput";

const SearchInputWithSelect = ({
	options,
	selectValue,
	selectChange,
	onChange,
	placeholder,
	selectPlaceholder,
	value = "",
	onSubmit = () => {},
	onClear = () => {},
}) => {
	return (
		<div
			style={{ borderColor: "#DEF1FF" }}
			className="tw-h-10 tw-bg-white tw-border tw-shadow-sm tw-rounded-md w-full tw-flex tw-items-center"
		>
			<div className="tw-h-full">
				<SelectInput
					bordered={false}
					options={options}
					placeholder={selectPlaceholder}
					value={selectValue}
					onChange={selectChange}
				/>
			</div>
			<div style={{ height: "70%", width: "1px" }} className="tw-bg-gray-300 tw-mr-1"></div>
			<div className="tw-flex tw-flex-1 tw-h-full tw-relative">
				<img
					src={require("../../../assets/icons/close.svg")}
					className="tw-w-2 tw-text-pc-grey9 tw-absolute"
					onClick={onClear}
					style={{ right: "50px", top: "50%", transform: "translateY(-50%)" }}
					alt=""
				/>
				<div className="tw-flex tw-flex-1 tw-items-center">
					<img
						src={require("../../../assets/icons/search-icon.svg")}
						className="tw-w-4 tw-mr-0"
						alt=""
					/>
					<input
						onChange={onChange}
						placeholder={placeholder}
						value={value}
						type="text"
						className="tw-flex-1 tw-h-full tw-max-w-full tw-overflow-x-hidden focus:tw-outline-none tw-text-sm tw-placeholder-gray-400 tw-text-gray-400 focus:tw-border-transparent tw-py-1 tw-pl-1 tw-pr-6"
					/>
				</div>
				<button
					type="submit"
					onClick={onSubmit}
					className="tw-w-10 tw-h-full tw-flex tw-justify-center tw-items-center tw-bg-pc-secondaryshade1 tw-rounded-tr-md tw-rounded-br-md  tw-text-white"
				>
					Go
				</button>
			</div>
		</div>
	);
};

export default SearchInputWithSelect;
