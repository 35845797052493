import React from "react";
import { Link } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";

const NewNavbar = ({ action }) => {
	return (
		<header className=" tw-fixed tw-shadow tw-z-40 tw-w-screen tw-top-0 tw-left-0 tw-right-0">
			<div className="global-container-amb tw-h-16 tw-flex tw-justify-between tw-items-center tw-bg-white">
				<Link to="/ambassador/dashboard">
					<img
						src={require("../../assets/icons/paycenterafrica-logo.svg")}
						alt="Paycenter Logo"
						className="tw-h-7"
					/>
				</Link>

				<HamburgerMenu
					onClick={() => {
						action();
					}}
				/>
			</div>
		</header>
	);
};

export default NewNavbar;
