import { useQuery } from "react-query";

const useFetchWithParams = (key, apiFunction, config) => {
	const fetchFunction = async ({ queryKey }) => {
		const [, params] = queryKey;
		// console.log(...Object.values(params));
		const response = await apiFunction(...Object.values(params));
		return response.data;
	};

	const data = useQuery(key, fetchFunction, config);

	// const data = useQuery([key, value], apiFunction, {
	// 	keepPreviousData: false,
	// 	refetchOnWindowFocus: false,
	// 	refetchOnMount: false,
	// });

	return data;
};

export default useFetchWithParams;
