import { resolve } from "url";

export function handleIsAuth() {
	return new Promise((resolve, reject) => {
		const user = JSON.parse(localStorage.getItem("user_pc"));
		if (user) {
			resolve(user);
		} else {
			reject(user);
		}
	});
}

export function signOut() {
	return new Promise((resolve, reject) => {
		localStorage.removeItem("user_pc");
		localStorage.removeItem("amb_pc");
		resolve();
	});
}
