const initialState = {
    header: ''
}

export default function modal(state= initialState, action){
  switch(action.type){
    case 'UPDATE_DASHBOARD_HEADER':
      return{
        ...state,
        header:action.payload.value,
      }
    break;
   default:
     return state;
  }
  return state;
}
