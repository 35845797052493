import React from "react";
import GlobalButton from "../../../../NewAmbassadorComponents/Buttons/GlobalButton";
import NewAmbassadorCard from "../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import AmbassadorDivider from "../../../../NewAmbassadorComponents/Divider/AmbassadorDivider";
import Skeleton from "react-loading-skeleton";
import useFetch from "../../../../Hooks/useFetch";
import { getAgentWalletDetails } from "../../../../Services/Dashboard";
import { formatNumber } from "../../../../utils/formatNumber";

const DashCard1 = () => {
	const { data, isSuccess, isLoading } = useFetch(
		"walet-details",
		getAgentWalletDetails
	);
	// console.log(data);
	const hasData = isSuccess && data !== null;
	return (
		<>
			{isLoading && <Loader />}
			{hasData && (
				<NewAmbassadorCard addition="">
					<span className="tw-block tw-text-pc-df-mobile text-center tw-text-pc-grey9 tw-mt-2">
						Available Commission
					</span>
					<h6 className="tw-font-bold tw-text-pc-h4-desktop text-center tw-text-black">
						₦{formatNumber(data.walletBalance)}
					</h6>
					<AmbassadorDivider addition="tw-my-2" />

					<div className="tw-flex tw-justify-between tw-items-center">
						<div>
							<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
								This Month
							</span>
							<p className="tw-font-bold tw-text-pc-df-mobile tw-mt-1 tw-text-black">
								₦{formatNumber(data.currentMonthCommission)}
							</p>
						</div>
						<div className="tw-text-right">
							<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
								Total Commission
							</span>
							<p className="tw-font-bold tw-text-pc-df-mobile tw-mt-1 tw-text-black">
								₦
								{formatNumber(data.walletBalance + data.currentMonthCommission)}
							</p>
						</div>
					</div>
					<div className="tw-mt-2 tw-mb-1 tw-grid tw-grid-cols-2 tw-gap-5">
						<GlobalButton
							label="View History"
							to="/ambassador/commission-history"
							addition="tw-w-full tw-bg-pc-secondaryshade2"
						/>
						<GlobalButton
							label="Withdraw"
							// disabled={true}
							// type="button"
							to="/ambassador/withdraw-commission"
							addition="tw-w-full tw-bg-pc-secondaryshade2"
						/>
					</div>
				</NewAmbassadorCard>
			)}
		</>
	);
};

export default DashCard1;

const Loader = () => {
	return (
		<NewAmbassadorCard addition="">
			<span className="tw-block tw-text-pc-df-mobile tw-text-pc-grey9 tw-mt-2">
				<Skeleton width={"45%"} />
			</span>
			<h6 className="tw-font-bold tw-text-pc-h4-desktop tw-mt-pc-8 tw-text-pc-secondaryshade2">
				<Skeleton width={"45%"} />
			</h6>
			<AmbassadorDivider addition="tw-my-2" />

			<div className="tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
						<Skeleton height={24} />
					</span>
					<p className="tw-font-bold tw-text-pc-df-mobile tw-mt-pc-8 tw-text-black">
						<Skeleton height={24} />
					</p>
				</div>
				<div className="tw-text-right">
					<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
						<Skeleton height={24} />
					</span>
					<p className="tw-font-bold tw-text-pc-df-mobile tw-mt-pc-8 tw-text-black">
						<Skeleton height={24} />
					</p>
				</div>
			</div>
			<div className="tw-mt-2 tw-mb-1 tw-grid tw-grid-cols-2 tw-gap-5">
				<Skeleton height={48} />
				<Skeleton height={48} />
			</div>
		</NewAmbassadorCard>
	);
};
