export function loadAccounts(accounts) {
	return {
		type: "LOAD_ACCOUNTS",
		payload: {
			accounts,
		},
	};
}
export function loadAgents(agents) {
	return {
		type: "LOAD_AGENTS",
		payload: {
			agents,
		},
	};
}

export function setSignedInUser(userDetails) {
	return {
		type: "SET_SIGNED_IN_USER",
		payload: {
			userDetails,
		},
	};
}

export function setAmbassadorData(data) {
	return {
		type: "SET_AMBASSADOR_DATA",
		payload: {
			data,
		},
	};
}

export function loadprofile(profileData) {
	return {
		type: "PROFILE_DATA",
		payload: {
			profileData,
		},
	};
}
export function logOutUser() {
	return {
		type: "LOGOUT_USER",
	};
}
