import React, { useEffect, useState } from "react";
import useFetch from "../../../../Hooks/useFetch";
import useFetchWithParams from "../../../../Hooks/useFetchWithParams";
import AgentCard from "../../../../NewAmbassadorComponents/AgentCard/AgentCard";
import GlobalButton from "../../../../NewAmbassadorComponents/Buttons/GlobalButton";
import NewAmbassadorCard from "../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import AmbassadorDivider from "../../../../NewAmbassadorComponents/Divider/AmbassadorDivider";

import DurationDropdown from "../../../../NewAmbassadorComponents/DurationDropdown/DurationDropdown";
import SearchInput from "../../../../NewAmbassadorComponents/Form/SearchInput/SearchInput";
import SearchInputWithSelect from "../../../../NewAmbassadorComponents/Form/SearchInput/SearchInputWithSelect";
import FullScreenLoader from "../../../../NewAmbassadorComponents/Loader/FullScreenLoader";
import SelectInput from "../../../../NewAmbassadorComponents/SelectInput.js/SelectInput";
import { agentSearch, getAgentsList } from "../../../../Services/Agents";
import AgentSearchResult from "../AgentSearch/AgentSearch";

const searchOptions = [
	// {
	// 	label: "Agent Group",
	// 	value: "agentGroupId",
	// },
	// {
	// 	label: "POS Type",
	// 	value: "posType",
	// },
	{
		label: "Business Name",
		value: "businessName",
	},
	// {
	// 	label: "Username",
	// 	value: "username",
	// },
	{
		label: "Phone Number",
		value: "phone",
	},
	// {
	// 	label: "Member Id",
	// 	value: "memberId",
	// },
];

// | id | name       |

// +----+------------+

// |  4 | BASIC      |

// |  7 | CHAMPS     |

// |  1 | INACTIVE   |

// |  5 | PARTNER    |

// |  6 | PREMIUM    |

// |  2 | ROOKIE     |
// |  3 | SUBSCRIBER |

const dropDownOptions = [
	{ label: "ALL", value: 0 },
	{ label: "BASIC", value: 4 },
	{ label: "CHAMPION", value: 7 },
	{ label: "INACTIVE", value: 1 },
	{ label: "PARTNER", value: 5 },
	{ label: "PREMIUM", value: 6 },
	{ label: "ROOKIE", value: 2 },
	{ label: "SUBSCRIBER", value: 3 },
];

const AgentDash2 = () => {
	const [searchResultArray, setSearchResultArray] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [searchFilter, setSearchFilter] = useState(null);
	const [showSearchResult, setShowSearchResult] = useState(false);

	const [dropDownValue, setDropDownValue] = useState(0);
	const [label, setLabel] = useState("ALL");

	const _handleDropdownValue = (value) => {
		setDropDownValue((prev) => value);
	};

	const [searchBy, setSearchBy] = useState({
		label: "Business Name",
		value: "businessName",
	});

	const { data, isLoading, isSuccess } = useFetchWithParams(
		["agent-list-truncated", { filter: dropDownValue }],
		getAgentsList
	);
	// console.log(data);
	const hasData = isSuccess && data !== undefined && data.data.data.length > 0;
	const isEmpty =
		isSuccess && data !== undefined && data.data.data.length === 0;

	const { isFetching: isLoading2 } = useFetchWithParams(
		["agent-search1", { value: searchFilter }],
		agentSearch,
		{
			enabled: searchFilter !== null ? true : false,
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			onSuccess: (data) => {
				setSearchResultArray(data.data.data);
				setShowSearchResult(true);
			},
		}
	);

	const _handleGoClick = () => {
		if (searchBy.value && searchValue.trim()) {
			const filter = `${searchBy.value}=${searchValue}`;
			setSearchFilter(filter);
		}
	};

	const _handleClear = () => {
		setSearchValue("");
		setSearchFilter(null);
	};

	const handleBackClick = () => {
		setSearchFilter(null);
		setShowSearchResult(false);
		setSearchValue("");
		setSearchResultArray((prev) => []);
		enableOverFlow();
	};

	const handleInputChange = (e) => {
		setSearchValue(e.target.value);
	};

	const _handleSearchByChange = (val) => {
		setSearchBy((prev) => val);
	};

	const preventOverflow = () => {
		const bodyElement = document.querySelector("body");
		bodyElement.style.overflowY = "hidden";
	};

	const enableOverFlow = () => {
		const bodyElement = document.querySelector("body");
		bodyElement.style.overflowY = "scroll";
	};
	useEffect(() => {
		if (showSearchResult) {
			preventOverflow();
		} else {
			enableOverFlow();
		}
	}, [searchResultArray]);

	return (
		<div className="tw-mt-8 tw-pb-8">
			{showSearchResult && (
				<AgentSearchResult
					data={searchResultArray}
					backAction={handleBackClick}
				/>
			)}

			{isLoading2 && <FullScreenLoader />}
			<div className="tw-flex tw-justify-between tw-items-center">
				<p className="tw-font-bold tw-mb-0 tw-text-black tw-text-pc-df-desktop">
					Agents
				</p>

				<DurationDropdown
					value={dropDownValue}
					label={label}
					setFunction={_handleDropdownValue}
					setLabel={setLabel}
					options={dropDownOptions}
				/>
			</div>
			<div className="tw-mt-6">
				<SearchInputWithSelect
					options={searchOptions}
					selectValue={searchBy}
					selectChange={_handleSearchByChange}
					onChange={handleInputChange}
					onClear={_handleClear}
					value={searchValue}
					onSubmit={_handleGoClick}
					placeholder="Search Agent"
					selectPlaceholder="Search By.."
				/>
			</div>
			<div className="tw-mt-8">
				{isLoading &&
					Array(10)
						.fill(1)
						.map((each) => {
							return <Loader />;
						})}

				{isEmpty && (
					<div
						style={{
							borderRadius: "10px",
							boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.04)",
						}}
						className="tw-h-24 tw-flex tw-justify-center tw-items-center tw-bg-white"
					>
						<p className="tw-text-pc-secondaryshade2 tw-mb-0 tw-font-semibold text-lg">
							No Agent Found
						</p>
					</div>
				)}

				{hasData &&
					data.data.data.map((each, index) => {
						return (
							<AgentCard
								key={`agentList_${index}`}
								data={each}
								color="tw-bg-green-500"
							/>
						);
					})}

				<div className="tw-mt-12 tw-flex tw-justify-center">
					<GlobalButton
						to="/ambassador/all-agents"
						label="View All Agents"
						addition="tw-px-8 tw-bg-pc-secondaryshade2"
					/>
				</div>
			</div>
		</div>
	);
};

export default AgentDash2;

function Loader() {
	return (
		<NewAmbassadorCard addition={"tw-mb-5"}>
			<div className="tw-flex tw-mt-2 tw-mb-4 tw-justify-end">
				<span
					className={`${"bg-gray-200"} tw-animate-pulse tw-bg-gray-200 tw-h-6 tw-w-6 tw-rounded-full`}
				></span>
			</div>
			<div>
				<span className="tw-text-pc-grey9 pulse-loader tw-w-24 tw-text-pc-df-desktop"></span>
				<div className="tw-flex tw-justify-between tw-items-center">
					<p className=" tw-text-pc-secondaryshade1 pulse-loader tw-w-24 tw-font-bold tw-mb-0 tw-text-pc-sm-mobile"></p>

					<span
						className="tw-flex tw-items-center tw-justify-center tw-px-2 tw-bg-white tw-py-1 tw-border tw-rounded-md hover:tw-no-underline tw-border-pc-secondaryshade2"
						to="#"
					>
						<span className="tw-text-pc-secondaryshade2 pulse-loader tw-w-24 tw-text-pc-sm-mobile  tw-mr-1"></span>
						<img
							src={require("../../../../assets/icons/chevron-right.svg")}
							className="tw-w-4 tw-ml-1"
							alt=""
						/>
					</span>
				</div>
			</div>
			<AmbassadorDivider addition="tw-my-4" />
			<div className="tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block pulse-loader tw-w-24 tw-text-pc-sm-mobile tw-text-pc-grey9"></span>
					<p className="tw-font-bold tw-mt-2 pulse-loader tw-w-24 tw-text-pc-df-mobile tw-mt-pc-6 tw-text-black"></p>
				</div>
				<div className="tw-text-right">
					<span className="tw-block pulse-loader tw-w-24 tw-text-pc-sm-mobile tw-text-pc-grey9"></span>
					<p className="tw-font-bold tw-mt-2 pulse-loader tw-w-24 tw-text-pc-df-mobile tw-mt-pc-6 tw-text-black"></p>
				</div>
			</div>
		</NewAmbassadorCard>
	);
}
