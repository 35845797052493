import React from "react";

const SearchInput = ({
	onChange,
	placeholder,
	value = "",
	onSubmit = () => {},
	onClear = () => {},
}) => {
	return (
		<div
			style={{ borderColor: "#DEF1FF" }}
			className="tw-h-10 tw-relative  tw-bg-white pl-3 tw-border tw-shadow-sm tw-rounded-md w-full tw-flex tw-items-center"
		>
			<img
				src={require("../../../assets/icons/close.svg")}
				className="tw-w-2 tw-text-pc-grey9 tw-absolute"
				onClick={onClear}
				style={{ right: "63px", top: "50%", transform: "translateY(-50%)" }}
				alt=""
			/>
			<div className="tw-flex tw-flex-1 tw-items-center">
				<img
					src={require("../../../assets/icons/search-icon.svg")}
					className="tw-w-5 tw-mr-3"
					alt=""
				/>
				<input
					onChange={onChange}
					placeholder={placeholder}
					value={value}
					type="text"
					className="tw-flex-1 tw-h-full focus:tw-outline-none tw-text-sm tw-placeholder-gray-400 tw-text-gray-400 focus:tw-border-transparent tw-py-1 tw-px-2"
				/>
			</div>
			<button
				type="submit"
				onClick={onSubmit}
				className="tw-w-12 tw-h-full tw-flex tw-justify-center tw-items-center tw-bg-pc-secondaryshade1 tw-rounded-tr-md tw-rounded-br-md  tw-text-white"
			>
				Go
			</button>
		</div>
	);
};

export default SearchInput;
