import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";

const SessionTimer = () => {
	const timerRef = useRef(null);

	const _handleLogout = () => {
		// console.log("timeout");
		localStorage.removeItem("amb_pc");
		localStorage.removeItem("user_pc");
		window.location.href = "/";
	};

	return (
		<IdleTimer
			ref={timerRef}
			element={document}
			onIdle={_handleLogout}
			debounce={250}
			timeout={1000 * 60 * 20}
		></IdleTimer>
	);
};

export default SessionTimer;
