import {combineReducers} from 'redux';
import form from './form';
import transactions from './transactions';
import modal from './modal';
import dashboard from './dashboard';
import user from './user';



export default combineReducers ({
    form,
    modal,
    transactions,
    dashboard,
    user
});