import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import styles from "./pagination.module.scss";

const NewPagination = ({ totalPages, changePage = () => {}, currentPage }) => {
	const [windowSize, setWindowSize] = useState(() => {
		return window.innerWidth;
	});

	useEffect(() => {
		const handleResize = () => {
			setWindowSize((prev) => {
				return window.innerWidth;
			});
		};

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const nextButton = () => {
		return (
			<div className="tw-h-8 tw-flex tw-px-3 tw-border tw-border-pc-secondaryshade1 tw-rounded tw-justify-center tw-items-center">
				{/* <span className="tw-mr-1 tw-text-pc-btn2-mobile tw-text-pc-secondaryshade1">
					Next
				</span> */}
				<img
					src={require("../../assets/icons/chevron-right.svg")}
					className="tw-w-4"
					alt=""
				/>
			</div>
		);
	};

	const previousButton = () => {
		return (
			<div className="tw-h-8 tw-flex tw-px-3 tw-border tw-border-pc-secondaryshade1 tw-rounded tw-justify-center tw-items-center">
				<img
					src={require("../../assets/icons/chevron-right.svg")}
					className="tw-w-4 tw-transform tw-rotate-180"
					alt=""
				/>
				{/* <span className="tw-ml-1 tw-text-pc-btn2-mobile tw-text-pc-secondaryshade1">
					Prev
				</span> */}
			</div>
		);
	};

	return (
		<ReactPaginate
			previousLabel={previousButton()}
			nextLabel={nextButton()}
			breakLabel={"..."}
			breakClassName={
				"tw-self-end tw-rounded-sm tw-border tw-border-pc-secondaryshade1 tw-h-8 tw-font-bold tw-text-pc-secondaryshade1 tw-text-center tw-w-6 tw-bg-white "
			}
			pageCount={totalPages}
			forcePage={currentPage - 1}
			marginPagesDisplayed={windowSize > 376 ? 2 : 2}
			pageRangeDisplayed={windowSize > 376 ? 2 : 0}
			onPageChange={changePage}
			containerClassName={"tw-flex tw-justify-center tw-items-center"}
			pageClassName={
				"tw-h-8 tw-text-pc-btn2-mobile tw-font-bold tw-text-center tw-w-10 tw-bg-white tw-border tw-border-pc-secondaryshade1 tw-flex tw-items-center tw-justify-center tw-mx-1 tw-rounded-sm"
			}
			activeClassName="tw-text-white tw-bg-pc-secondaryshade1"
			activeLinkClassName={styles.activeClass}
			pageLinkClassName="tw-text-pc-secondaryshade1 hover:tw-text-white hover:tw-no-underline tw-no-underline"
			// initialPage={currentPage}
		/>
	);
};

export default NewPagination;
