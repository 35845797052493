import { useEffect } from "react";
// import { history } from "../../App";

export default function ScrollToTop() {
	// console.log(history);
	// const { pathname } = history.location;

	useEffect(() => {
		window.scrollTo(0, 0);
		console.log("scrolled");
	}, []);

	return null;
}
