import React from "react";
import AmbassadorArrow from "../../../../NewAmbassadorComponents/Arrow/AmbassadorArrow";
import NewAmbassadorCard from "../../../../NewAmbassadorComponents/Cards/NewAmbassadorCard";
import AmbassadorDivider from "../../../../NewAmbassadorComponents/Divider/AmbassadorDivider";

// import ReactApexChart from "apexcharts";
import NewApexChart from "../../../../NewAmbassadorComponents/Chart/NewAmbassadorChart";
import useFetch from "../../../../Hooks/useFetch";
import { getAgentsBreakdown } from "../../../../Services/Agents";

// const dummyData = [
// 	{ name: "Champion", color: "tw-bg-black" },
// 	{ name: "Partner", color: "tw-bg-green-500" },
// 	{ name: "Premium", color: "tw-bg-purple-500" },
// 	{ name: "Subscriber", color: "tw-bg-red-500" },
// 	{ name: "Basic", color: "tw-bg-blue-500" },
// 	{ name: "Rookie", color: "tw-bg-yellow-500" },
// ];

const AgentDash1 = () => {
	const { isSuccess, isLoading, data } = useFetch("agentBreakdown", getAgentsBreakdown);
	const hasData = isSuccess && data !== undefined && data.length > 0;
	console.log(data);

	const total = hasData
		? data.reduce((acc, data) => {
				return acc + data.count;
		  }, 0)
		: 0;

	const inactive =
		hasData && data.find((each) => each.name === "INACTIVE") !== undefined
			? data.find((each) => each.name === "INACTIVE").count
			: 0;

	return (
		<>
			<div className="tw-flex tw-justify-between tw-mb-3 tw-items-center">
				<p className="tw-font-bold tw-mb-0 tw-text-black tw-text-pc-df-desktop">
					Agents by Category
				</p>
			</div>
			<NewAmbassadorCard>
				<div className="tw-mb-1 tw-flex tw-justify-between tw-items-center">
					<div>
						<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
							Total
						</span>
						<div className="tw-flex tw-justify-center tw-items-center">
							{hasData && (
								<p className="tw-font-bold tw-mb-0 tw-mr-3 tw-text-pc-df-mobile tw-text-black">
									{total}
								</p>
							)}
							{isLoading && (
								<p className="tw-w-12 tw-h-8 tw-rounded tw-bg-gray-200 tw-animate-pulse"></p>
							)}
						</div>
					</div>
					<div>
						<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
							Active
						</span>
						<div className="tw-flex tw-justify-center tw-items-center">
							{hasData && (
								<>
									<p className="tw-font-bold tw-mb-0 tw-mr-3 tw-text-pc-df-mobile tw-text-black">
										{total - inactive}
									</p>
									<AmbassadorArrow type="increaseArrow" />
								</>
							)}
							{isLoading && (
								<p className="tw-w-12 tw-h-8 tw-rounded tw-bg-gray-200 tw-animate-pulse"></p>
							)}
						</div>
					</div>
					<div>
						<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
							Inactive
						</span>
						<div className="tw-flex tw-justify-center tw-items-center">
							{hasData && (
								<>
									<p className="tw-font-bold tw-mb-0 tw-mr-3 tw-text-pc-df-mobile tw-text-black">
										{inactive}
									</p>
									<AmbassadorArrow type="decreaseArrow" />
								</>
							)}
							{isLoading && (
								<p className="tw-w-12 tw-h-8 tw-rounded tw-bg-gray-200 tw-animate-pulse"></p>
							)}
						</div>
					</div>
				</div>
				<AmbassadorDivider addition="tw-my-5" />
				<div
					style={{
						minHeight: "150px",
						maxWidth: "100%",
						marginLeft: "auto",
						marginRight: "auto",
						overflowX: "hidden",
					}}
				>
					{isLoading && <Loader />}
					{hasData && <NewApexChart data={data} />}
				</div>
			</NewAmbassadorCard>
		</>
	);
};

export default AgentDash1;

function Loader() {
	return (
		<div>
			<div className="tw-flex tw-justify-center">
				<div className="tw-w-52 tw-h-52 tw-rounded-full tw-bg-gray-200 tw-flex tw-justify-center tw-items-center tw-animate-pulse">
					<div className="tw-w-36 tw-h-36 tw-rounded-full tw-bg-white"></div>
				</div>
			</div>
			<div className="tw-grid tw-mt-4 tw-grid-cols-3 tw-gap-3 tw-justify-center">
				<div className="tw-h-8 tw-rounded tw-bg-gray-200 tw-animate-pulse"></div>
				<div className="tw-h-8 tw-rounded tw-bg-gray-200 tw-animate-pulse"></div>
				<div className="tw-h-8 tw-rounded tw-bg-gray-200 tw-animate-pulse"></div>
				<div className="tw-h-8 tw-rounded tw-bg-gray-200 tw-animate-pulse"></div>
				<div className="tw-h-8 tw-rounded tw-bg-gray-200 tw-animate-pulse"></div>
				<div className="tw-h-8 tw-rounded tw-bg-gray-200 tw-animate-pulse"></div>
			</div>
		</div>
	);
}
