import React, { useState, useEffect } from "react";

import ReactApexChart from "react-apexcharts";

const sortAndExtractData = (arr) => {
	const colorPallete = {
		SUBSCRIBER: "#D21C1C",
		PARTNER: "#18D7A9",
		PREMIUM: "#9B51E0",
		CHAMPION: "#000000",
		BASIC: "#4285F4",
		ROOKIE: "#FFC200",
	};

	const series = [];
	const labels = [];
	const colors = [];
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].name !== "INACTIVE") {
			series.push(arr[i].count);
			labels.push(`${arr[i].name.slice(0, 1)}${arr[i].name.slice(1).toLowerCase()}`);
			colors.push(colorPallete[arr[i].name]);
		}
	}

	return [series, labels, colors];
};

export default function NewApexChart({ data }) {
	const [series, labels, colors] = sortAndExtractData(data);
	console.log(series, labels, colors);
	const [windowSize, setWindowSize] = useState(() => {
		return window.innerWidth;
	});

	const options = {
		series: series,
		// series: [10, 20, 30, 10, 5, 15],

		options: {
			chart: {
				type: "donut",
			},
			colors: colors,
			// colors: ["#000000", "#18D7A9", "#9B51E0", "#D21C1C", "#4285F4", "#FFC200"],
			stroke: {
				show: true,
				curve: "smooth",
				lineCap: "butt",
				colors: undefined,
				width: 1,
				dashArray: 8,
			},
			labels: labels,
			// labels: ["Champion", "Partner", "Premium", "Subscriber", "Basic", "Rookie"],
			dataLabels: {
				enabled: false,

				style: {
					fontSize: "10px",
				},
			},
			responsive: [
				{
					breakpoint: 500,
					options: {
						chart: {
							width: 350,
							// width: windowSize > 350 ? 380 : 150,

							offsetX: 0,
						},
						legend: {
							// show: false,
							position: "bottom",
							fontSize: windowSize > 350 ? "12px" : "10px",
							height: 90,
							width: 320,
							marginLeft: "auto",
						},
					},
				},
			],
			plotOptions: {
				pie: {
					donut: {
						size: "75%",
						labels: {
							show: true,
							name: {
								// show: true,
							},
							value: {
								// show: true,
							},

							total: {
								// show: true,
								// showAlways: true,
								label: "Total",
								fontSize: "18px",
								// fontFamily: "Helvetica, Arial, sans-serif",
								fontFamily: "`NunitoSans-Regular', sans-serif",
								fontWeight: 600,
								color: "#373d3f",
							},
						},
					},
				},
			},
		},
	};

	useEffect(() => {
		const handleResize = () => {
			setWindowSize((prev) => {
				return window.innerWidth;
			});
		};

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div id="chart apexOuter">
			<ReactApexChart
				style={{ display: "flex", justifyContent: "center" }}
				options={options.options}
				series={options.series}
				type="donut"
			/>
		</div>
	);
}
