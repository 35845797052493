import React, { forwardRef } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import calendar from "../../assets/icons/calendar.svg";

const CustomDatePicker = ({ date, setDate, text }) => {
	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div className="tw-rounded tw-bg-white tw-p-2 tw-px-4" onClick={onClick} ref={ref}>
			<p className="tw-mb-1 tw-font-semibold tw-text-sm tw-text-pc-grey8">{text}:</p>
			<div className="tw-flex tw-items-center">
				<span className="tw-text-base tw-text-pc-grey5">
					{dayjs(value).format("DD-MM-YYYY")}
				</span>
				<img src={calendar} className="tw-ml-1 tw-w-5" alt="" />
			</div>
		</div>
	));
	return (
		<DatePicker
			selected={date}
			onChange={(date) => setDate(date)}
			customInput={<ExampleCustomInput />}
		/>
	);
};

export default CustomDatePicker;
