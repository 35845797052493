import http from "./Axios-Config";

export const getCommissionHistory = async (page, filter) => {
	const currentYear = new Date().getFullYear();
	let _startYear;
	let _endYear;
	let query;

	if (filter === "all") {
		_startYear = 2019;
		_endYear = currentYear;
	} else if (filter === "this-year") {
		_startYear = currentYear;
		_endYear = currentYear;
	} else if (filter === "last-year") {
		_startYear = currentYear - 1;
		_endYear = currentYear - 1;
	} else if (filter === "2-years-ago") {
		_startYear = currentYear - 2;
		_endYear = currentYear - 2;
	}

	if (filter.startsWith("?")) {
		query = `${filter}&startPage=${page - 1}`;
	} else {
		query = `?startYear=${_startYear}&endMonth=12&endYear=${_endYear}&startPage=${
			page - 1
		}&length=15&startMonth=1`;
	}





	return await http.get(`/commission/filter${query}`);
};
