import React from "react";

const Test = () => {
	return (
		<div className="h-60 tail-bg-pc-lightblue">
			<p className="tail-font-extrabold tail-text-blue-500 tail-animate-pulse tail-text-center">
				Hello guys
			</p>
			<div className="tail-h-16 tail-w-4/5 tail-mx-auto tail-animate-pulse tail-bg-gray-200 tail-rounded"></div>
		</div>
	);
};

export default Test;
