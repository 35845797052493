import React, { useState, useEffect } from "react";
import { formatNumber } from "../../utils/formatNumber";
import NewAmbassadorCard from "../Cards/NewAmbassadorCard";
import AmbassadorDivider from "../Divider/AmbassadorDivider";

const capitalizeFirstLetter = (val) => {
	const firstLetter = val.slice(0, 1).toUpperCase();
	return `${firstLetter}${val.slice(1).toLowerCase()}`;
};

const determineStatusColor = (val) => {
	if (val === "00") {
		return "green";
	} else if (val === "09" || val === "PP") {
		return "yellow";
	} else return "red";
};

const TransactionCard = ({ marginBottom = "tw-mb-5", data }) => {
	const [copied, setCopied] = useState(false);

	const copyText = async (val) => {
		const el = document.createElement("textarea");
		el.value = val;
		el.setAttribute("readonly", "");
		el.style.position = "absolute";
		el.style.opacity = 0;
		el.style.left = "-9999px";
		document.body.appendChild(el);
		el.select();
		el.setSelectionRange(0, 99999);
		document.execCommand("copy");
		document.body.removeChild(el);
		setCopied(true);
	};

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 1500);
		}
	}, [copied]);

	return (
		<NewAmbassadorCard addition={marginBottom}>
			<div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
				<div className="tw-flex tw-items-center">
					<img
						src={require("../../assets/icons/withdraw-icon.svg")}
						className="tw-w-10"
						alt=""
					/>
					<span className="tw-font-bold tw-text-pc-sm-mobile tw-text-black tw-ml-2">
						{data.agentBusinessName}
					</span>
				</div>

				<span
					style={{ paddingTop: "1px", paddingBottom: "1px", lineHeight: "16px" }}
					className={`tw-bg-${determineStatusColor(
						data.statusCode
					)}-400 tw-px-2 tw-rounded tw-text-white tw-text-pc-btn2-mobile`}
				>
					{capitalizeFirstLetter(data.statusMessage)}
				</span>
			</div>

			<div className="tw-flex tw-justify-between tw-items-center">
				<div>
					<span className="tw-block tw-text-pc-df-mobile tw-text-pc-grey9">
						{data.transactionType}
					</span>
					<p className="tw-font-bold tw-text-pc-h5-mobile tw-mt-pc-6 tw-text-black">
						₦{formatNumber(data.totalAmount)}
					</p>
				</div>
				<div className="tw-text-right">
					<span className="tw-block tw-text-pc-df-mobile tw-text-pc-grey9">
						Commission
					</span>
					<p className="tw-font-bold tw-text-pc-h5-mobile tw-mt-pc-6 tw-text-black">
						₦{formatNumber(data.ambassadorCut)}
					</p>
				</div>
			</div>

			<AmbassadorDivider addition="tw-my-2" />

			<div className="tw-flex tw-justify-between tw-items-center">
				<div className="tw-relative">
					{copied && (
						<span className="tw-px-3 tw-py-1 tw-rounded tw-right-0 tw-top--3 tw-bg-black tw-inline-block tw-absolute tw-bg-opacity-80 tw-text-xs tw-text-white">
							Text Copied
						</span>
					)}
					<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">
						Transaction ID
					</span>
					<p className="tw-font-bold tw-text-xs tw-flex tw-justify-between tw-items-center-pc-6 tw-text-black">
						<span>{data.transactionId}</span>
						<img
							onClick={() => copyText(data.transactionId)}
							src={require("../../assets/icons/trans-id.svg")}
							alt=""
							className="tw-3 tw-ml-2 tw-cursor-auto"
						/>
					</p>
				</div>
				<div className="tw-text-right">
					<span className="tw-block tw-text-pc-sm-mobile tw-text-pc-grey9">Date</span>
					<p className="tw-font-bold tw-text-xs tw-mt-pc-6 tw-text-black">
						{data.appTime.slice(0, 16)}
					</p>
				</div>
			</div>
		</NewAmbassadorCard>
	);
};

export default TransactionCard;
