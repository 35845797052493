import React from "react";

const CommissionLoader = () => {
	return (
		<>
			{[1, 2, 3, 4, 5].map((each) => {
				return (
					<div
						style={{ borderColor: "#DEF1FF" }}
						className={`tw-bg-white tw-border tw-shadow-sm tw-rounded-xl tw-flex tw-justify-between tw-mb-3 tw-items-center tw-p-1 tw-px-3`}
					>
						<div>
							<span className="tw-block tw-h-6 tw-w-16 tw-rounded tw-bg-gray-200 tw-animate-pulse pc-font-regular tw-text-pc-df-mobile tw-text-pc-grey9 tw-mt-2"></span>
							<p className="tw-font-bold tw-h-6 tw-w-24 tw-rounded tw-bg-gray-200 tw-animate-pulse pc-font-bold tw-text-pc-h5-mobile tw-mt-pc-8 tw-text-pc-secondaryshade2"></p>
						</div>

						<span
							style={{ background: "#eaeaea", fontSize: "13px" }}
							className={`tw-text-pc-grey9 tw-px-3 tw-h-8 tw-w-24 tw-bg-gray-200 tw-animate-pulse tw-rounded-md tw-cursor-pointer focus:tw-outline-none tw-flex pc-font-bold tw-items-center tw-justify-center tw-text-center tw-py-2 focus:tw-text-white focus:tw-no-underline`}
						></span>
					</div>
				);
			})}
		</>
	);
};

export default CommissionLoader;
