import React, { Component } from "react";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return (
				<div
					className="tw-h-screen tw-w-screen tw-flex tw-px-8 tw-justify-center tw-items-center"
					style={{ backgroundColor: "#F4F5F6" }}
				>
					<div className="tw-bg-white tw-py-4 tw-shadow tw-px-6 tw-rounded-lg">
						<p className="tw-text-center tw-font-bold tw-text-red-400">
							Something went wrong.
							<br /> Please refresh your browser.
						</p>
						<img
							src={require("../../assets/img/refresh-icon.gif")}
							className="tw-w-8 tw-block tw-mx-auto tw-mt-1 tw-mb-0"
							alt=""
						/>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
