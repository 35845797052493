import React from "react";
import ReactDOM from "react-dom";

const FullScreenLoader = () => {
	return ReactDOM.createPortal(
		<div className="tw-fixed tw-z-50 tw-top-0 tw-flex tw-items-center tw-justify-center tw-bottom-0 tw-left-0 tw-right-0 tw-w-screen tw-h-screen tw-bg-black tw-bg-opacity-70">
			<div className="loadingio-spinner-dual-ball-irtiwddwtg9">
				<div className="ldio-h1l5zfkgqaf">
					<div></div>
					<div></div>
					{/* <div></div> */}
				</div>
			</div>
		</div>,
		document.body
	);
};

export default FullScreenLoader;
