const initialState = {
    visibility:false,
    activationCode: ''
}

export default function modal(state= initialState, action){
  switch(action.type){
    
    case 'TOGGLE_MODAL_VISIBILITY':
      return{
        ...state,
        visibility:action.payload.value,
        activationCode:action.payload.activationCode
      }
    break;
   default:
     return state;
  }
  return state;
}
